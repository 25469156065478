import PhoneCodeInterface from "@homegame/common/dist/interface/phone-code.interface";
import BaseService from "./base.service";
import { axios } from "../api";
import { API_URL } from "../config";
import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";

export interface SignUpInterface {
  name: string;
  phone: string;
}

interface SignIn {
  email: string;
  password: string;
}

interface SendCode {
  phone: string;
}

interface VerifyCode {
  phone: string | number;
  code: string;
}

export default class AuthService extends BaseService {
  baseEndpoint = "auth";

  async signUp(data: SignUpInterface): Promise<{
    expiresIn: number;
    accessToken: string;
    refreshToken?: string;
    tablePlayer?: TablePlayerInterface;
  }> {
    try {
      return await axios.post(`${API_URL}${this.baseEndpoint}/sign-up`, data);
    } catch (e) {
      console.error(e);
      throw BaseService.handleError(e);
    }
  }

  async login(data: SignIn): Promise<{ accessToken: string }> {
    try {
      return await axios.post(`${API_URL}${this.baseEndpoint}/login`, data);
    } catch (e) {
      console.error(e);
      throw BaseService.handleError(e);
    }
  }

  async sendCode(data: SendCode): Promise<PhoneCodeInterface | Error> {
    try {
      return await axios.post(`${API_URL}${this.baseEndpoint}/send-code`, data);
    } catch (e) {
      throw BaseService.handleError(e);
    }
  }

  async sendCodeCall(data: SendCode): Promise<PhoneCodeInterface | Error> {
    try {
      return await axios.post(
        `${API_URL}${this.baseEndpoint}/send-code-call`,
        data
      );
    } catch (e) {
      throw BaseService.handleError(e);
    }
  }

  async verifyCode(
    data: VerifyCode
  ): Promise<{ accessToken?: string; refreshToken?: string; hash?: string }> {
    return await axios.post(`${API_URL}${this.baseEndpoint}/verify-code`, data);
  }

  /**
   * refresh token
   */
  async refreshToken(): Promise<any> {
    return super.request({
      method: "post",
      url: `${this.baseEndpoint}/refresh-token`,
    });
  }
}
