import { useState } from "react";
import ConfirmDialog from "../../core/components/confirmation-dialog.component";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import GameService from "../../core/service/game.service";
import Button from "@mui/material/Button";
import { useToast } from "../../core/hooks/use-toast.hook";
import { useGame } from "../game.context";

interface MakeCashierBtnComponentProps {
  user: UserInterface | undefined;
  onDone?: () => void;
}

export default function MakeCashierBtnComponent({
  user,
  onDone,
}: MakeCashierBtnComponentProps) {
  const { showToast } = useToast();
  const gameService = new GameService();

  const { isCashier, isCanceled, isFinished, game } = useGame();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);

  if (!user || !isCashier || isCanceled || isFinished || !game) return <></>;

  const closeConfirmation = () => setIsOpenConfirmation(false);
  const openConfirmation = () => {
    setIsOpenConfirmation(true);
  };

  const handleAction = async () => {
    setIsLoading(true);
    try {
      await gameService.changeCashier(game.id, user?.id);
      showToast(`${user.name} is now cashier.`, "warning");
      if (onDone) onDone();
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        style={{ textShadow: "none" }}
        onClick={openConfirmation}
      >
        Make cashier
      </Button>

      <ConfirmDialog
        text={`Are you sure you want to make ${user.name} cashier?`}
        isLoading={isLoading}
        actionText="Make"
        open={isOpenConfirmation}
        onClose={closeConfirmation}
        action={handleAction}
      />
    </>
  );
}
