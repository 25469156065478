import { createContext, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import dayjsutc from "dayjs/plugin/utc";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import GamesService from "../core/service/game.service";
import { useSocket } from "../core/context/socket.context";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import { useAuth } from "../core/context/auth.context";
import UserRole from "@homegame/common/dist/enum/user-role.enum";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import GameInfoInterface from "../core/hooks/useGameInfo/interface/game-info.interface";

dayjs.extend(dayjsutc);

interface GameContextInterface {
  game?: GameInterface;
  reloadGame: () => Promise<void>;
  playersWithErrors: number[];
  setPlayersWithErrors: (playersWithErrors: number[]) => void;
  isCashier: boolean;
  isNew: boolean;
  isCanceled: boolean;
  isOngoing: boolean;
  isFinished: boolean;
  isSuperAdmin: boolean;
  isFetching: boolean;
  gameId?: string;
  gameStats?: GameInfoInterface;
}

const defaultGameContext: GameContextInterface = {
  game: undefined,
  reloadGame: () => Promise.resolve(),
  playersWithErrors: [],
  setPlayersWithErrors: () => {},
  isCashier: false,
  isNew: false,
  isCanceled: false,
  isOngoing: false,
  isFinished: false,
  isSuperAdmin: false,
  isFetching: false,
  gameId: undefined,
  gameStats: undefined,
};

export const GameContext =
  createContext<GameContextInterface>(defaultGameContext);

export const GameContextProvider = ({ children }: { children: any }) => {
  const { user } = useAuth();

  const { gameId } = useParams();
  const gamesService = new GamesService();
  const { gameSocket } = useSocket();

  const [playersWithErrors, setPlayersWithErrors] = useState<number[]>([]);

  const {
    data: game,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [`singleGameData`, { gameId }],
    queryFn: () => gamesService.single(gameId),
    initialData: undefined,
  });

  const gameStats = useGameInfo(game);

  const isCashier = user?.id === game?.cashierId;

  const isNew = game?.status === GameStatus.NEW;
  const isCanceled = game?.status === GameStatus.CANCELED;
  const isOngoing = game?.status === GameStatus.ONGOING;
  const isFinished = game?.status === GameStatus.FINISHED;
  const isSuperAdmin = user?.role.includes(UserRole.SUPERADMIN) || false;

  useEffect(() => {
    const events = [
      "gameUpdated",
      "gamePlayerUpdated",
      "gamePlayerCreated",
      "gamePlayerRemoved",
    ];
    if (gameSocket) {
      events.forEach((eventName) => {
        gameSocket.on(eventName, (data) => {
          if (gameId && +gameId === +data.gameId) {
            refetch();
          }
        });
      });

      return () => {
        events.forEach((eventName) => {
          gameSocket.off(eventName);
        });
      };
    }
  }, [gameSocket]);

  const reloadGame = async () => {
    await refetch();
    setPlayersWithErrors([]);
  };

  const value = {
    game,
    reloadGame,
    playersWithErrors,
    setPlayersWithErrors,
    isCashier,
    isNew,
    isCanceled,
    isOngoing,
    isFinished,
    isSuperAdmin,
    gameId,
    gameStats,
    isFetching,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export const useGame = (): GameContextInterface => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
