import TransactionType from '@homegame/common/dist/enum/transaction-type.enum';
import GameInterface from '@homegame/common/dist/interface/game.interface';
import getPlayerLastTransaction from './get-player-last-transaction';
import GamePlayerStatus from '@homegame/common/dist/enum/game-player-status.enum';

/**
 * Checks if all players in a game have done cash out transactions.
 *
 * @param {GameInterface} [game] - The game data. If not provided, the function will return false.
 * @returns {boolean} Returns true if all players in the game have done cash out transactions. Otherwise, returns false.
 *
 * If the game data is not provided or incomplete, the function will return false.
 */
export default function isAllCashOutsDone(game: GameInterface | undefined): boolean {
	// Return false if the game data is not defined or incomplete
	if (!game || !game.players) return false;

	// Check if all players have done cash out transactions
	return game.players
		.filter((gp) => gp.status === GamePlayerStatus.REGISTERED)
		.filter((gamePlayer) => {
			return (
				game.transactions!.filter(
					(transaction) => transaction.type === TransactionType.BUY_IN && transaction.fromUserId === gamePlayer.playerId
				).length > 0
			);
		})
		.every((player) => {
			if (!player.playerId) return false;
			const lastTransaction = getPlayerLastTransaction(player.playerId, game.transactions);
			return lastTransaction?.type === TransactionType.CASH_OUT;
		});
}
