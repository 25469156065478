import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import GamePlayerInterface from "@homegame/common/dist/interface/game-player.interface";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "@mui/material";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles(() =>
  createStyles({
    statusChip: {
      position: "absolute",
      top: -5,
      right: -5,
      zIndex: 999,
      background: "green",
      padding: 0,
      height: 15,
      color: "white",
      "& .MuiSvgIcon-root ": {
        color: "#fff",
        fontSize: 8,
        marginRight: -2,
      },
      "& .MuiChip-label ": {
        fontSize: 9,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
  })
);

interface PlayerStatusComponentProps {
  table?: TableInterface;
  gamePlayer: GamePlayerInterface;
}

export default function PlayerStatusComponent({
  table,
  gamePlayer,
}: PlayerStatusComponentProps) {
  const classes = useStyles();

  if (!table) return <></>;

  let playerStatusText = capitalize(gamePlayer.status || "");

  let backgroundColor = "#356ab3";

  let icon = <HourglassFullIcon />;
  if (gamePlayer.status === GamePlayerStatus.REGISTERED) {
    icon = <CheckIcon />;
    backgroundColor = "#4caf50";
  }
  if (gamePlayer.status === GamePlayerStatus.UNREGISTERED) {
    icon = <CancelIcon />;
    backgroundColor = "#f44336";
    playerStatusText = "Declined";
  }

  return (
    <Chip
      component="span"
      className={classes.statusChip}
      style={{ backgroundColor }}
      label={playerStatusText}
      icon={icon}
    />
  );
}
