export default function InlineLoadingComponent() {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				position: 'relative',
			}}
		>
			<img
				src="/chip.png"
				className="loader-main_icon"
				alt="preloader"
				style={{ width: 50, height: 50, position: 'absolute', left: '50%', top: '50%', marginLeft: -25, marginTop: -25 }}
			/>
		</div>
	);
}
