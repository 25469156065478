import GameInterface from "@homegame/common/dist/interface/game.interface";
import { useEffect, useState } from "react";
import { useAuth } from "../core/context/auth.context";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";

dayjs.extend(dayjsDuration);

interface GameDurationProps {
  game?: GameInterface;
  textVariant?:
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | undefined;
}

export default function GameDuration({
  game,
  textVariant = "h5",
}: GameDurationProps) {
  const { user } = useAuth();
  const [durationString, setDurationString] = useState<string>("");

  useEffect(() => {
    const setDuration = () => {
      if (!game) return;
      let duration = dayjs.duration(dayjs().diff(dayjs(game.startedAt)));
      if (game.status === GameStatus.FINISHED) {
        duration = dayjs.duration(
          dayjs(game.finishedAt).diff(dayjs(game.startedAt)),
        );
      }
      if (game.status === GameStatus.ONGOING) {
        duration = dayjs.duration(dayjs().diff(dayjs(game.startedAt)));
      }
      setDurationString(duration?.format("H:mm:ss"));
    };

    setDuration();

    if (game?.status === GameStatus.FINISHED) return;

    const interval = setInterval(setDuration, 1000);
    return () => clearInterval(interval);
  }, [game]);

  if (!game || !user || !game.startedAt) return <></>;

  return (
    <Typography
      variant={textVariant}
      gutterBottom
      style={{ textAlign: "center" }}
    >
      {durationString}
    </Typography>
  );
}
