import React from "react";
import SignUpPage from "../sign-up/sign-up.page";
import ProfilePage from "../profile/profile.page";
import NotFoundPage from "../not-found/not-found.page";
import TablesPage from "../tables/tables.page";
import EditTablePage from "../tables/table-edit.page";
import ViewTablePage from "../tables/table-view.page";
import TransactionsPage from "../transactions/transactions.page";
import SignInPhonePage from "../sign-in-phone/sign-in-phone.page";
import StatsPage from "../stats/stats.page";
import { Navigate, useRoutes } from "react-router-dom";
import PrivateRoute from "./private.route";
import AdminPage from "../admin/admin.page";
import GamePage from "../game/game.page";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Navigate to="tables" />,
    },
    {
      path: "profile",
      element: (
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      ),
    },
    {
      path: "admin",
      element: (
        <PrivateRoute>
          <AdminPage />
        </PrivateRoute>
      ),
    },
    {
      path: "transactions/:tabName?",
      element: (
        <PrivateRoute>
          <TransactionsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "tables",
      element: (
        <PrivateRoute>
          <TablesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "tables/add",
      element: (
        <PrivateRoute>
          <EditTablePage />
        </PrivateRoute>
      ),
    },
    {
      path: "tables/:tableId",
      element: (
        <PrivateRoute>
          <EditTablePage />
        </PrivateRoute>
      ),
    },
    {
      path: "tables/:tableId/view",
      element: (
        <PrivateRoute>
          <ViewTablePage />
        </PrivateRoute>
      ),
    },
    {
      path: "game/:gameId/:tabName?",
      element: (
        <PrivateRoute>
          <GamePage />
        </PrivateRoute>
      ),
    },
    {
      path: "sign",
      element: <SignInPhonePage />,
    },
    {
      path: "sign-up",
      element: <SignUpPage />,
    },
    {
      path: "stats",
      element: (
        <PrivateRoute>
          <StatsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return routes;
}
