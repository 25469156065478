import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import dayjs from "dayjs";
import { useGame } from "./game.context";

const useStyles = makeStyles(() =>
  createStyles({
    gameStatusInfo: {
      fontSize: 13,
      marginBottom: 0,
    },
  })
);

export default function GameStatusText() {
  const classes = useStyles();
  const { game, isNew, isOngoing, isFinished } = useGame();

  if (!game || isOngoing) return <> </>;

  let statusText = `Game ${isFinished ? "finished" : "canceled"} ${
    isFinished ? dayjs(game.finishedAt).format("D MMMM YYYY") : ""
  }`;

  if (isNew)
    statusText = `Game starts ${dayjs(game.date).format("D MMMM YYYY")}`;

  return (
    <Typography variant="h6" gutterBottom className={classes.gameStatusInfo}>
      {statusText}
    </Typography>
  );
}
