import BasePage from "../core/base.page";
import GameView from "./game-view";
import { GameContextProvider } from "./game.context";

export default function GamePage() {
  return (
    <BasePage>
      <GameContextProvider>
        <GameView />
      </GameContextProvider>
    </BasePage>
  );
}
