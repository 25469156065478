import { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles(() =>
  createStyles({
    heading1: {
      color: "#47528c",
      textShadow: "none",
      fontSize: 30,

      "& p": {
        color: "#47528c",
        textShadow: "none",
        fontSize: 30,
        display: "inline",
      },
    },
    subtitle: {
      fontSize: 12,
      color: "#47528c",
      textShadow: "none",
      "& small": {
        // color: "#63bc65",
        color: "rgba(245,0,87,.6)",
        fontWeight: "bold",
        fontSize: 10,
      },
    },
    divider: {
      width: "100%",
      height: 1,
      borderColor: "#fff",
      borderWidth: 1,
      marginTop: 5,
      marginBottom: 2,
    },
    card: {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      padding: 15,
      paddingBottom: 0,
      borderRadius: 15,
      marginBottom: 15,
      height: "100%",
    },
    iconWrapper: {
      textAlign: "center",
      "& span": {
        textShadow: "none",
        fontSize: 27,
        lineHeight: "32px",
      },
      "& svg": {
        marginBottom: -3,
        color: "#fff",
      },
    },
  })
);

interface CardProps {
  icon?: ReactNode;
  primary: ReactNode;
  secondary: ReactNode;
  border?: string;
}

export default function CardComponent({
  icon,
  primary,
  secondary,
  border,
}: CardProps) {
  const classes = useStyles();

  return (
    <div className={classes.card} style={border ? { border } : {}}>
      <Typography
        variant="body1"
        textAlign="center"
        className={classes.iconWrapper}
      >
        {!!icon && icon}
      </Typography>

      <Typography variant="h4" textAlign="center" className={classes.heading1}>
        {primary}
      </Typography>
      <Divider className={classes.divider} />

      <Typography className={classes.subtitle}>{secondary}</Typography>
    </div>
  );
}
