import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useAuth } from './auth.context';
import { getCookie } from '../helpers/storage.helper';
import { API_URL, TOKEN_COOKIE_NAME } from '../config';

export interface SocketsListInterface {
	gameSocket: Socket | null;
	tableSocket: Socket | null;
	transactionSocket: Socket | null;
}

interface SocketProviderProps {
	children: ReactNode;
}

const SocketContext = createContext<SocketsListInterface | undefined>(undefined);

export const SocketProvider = ({ children }: SocketProviderProps) => {
	const { user } = useAuth();
	const [sockets, setSockets] = useState<SocketsListInterface>({
		gameSocket: null,
		tableSocket: null,
		transactionSocket: null,
	});

	useEffect(() => {
		if (user) {
			const connectionOptions = {
				transports: ['websocket'],
				path: '/ws',
				auth: (cb: any) => {
					const token = getCookie(TOKEN_COOKIE_NAME);
					if (token) cb({ token });
				},
				withCredentials: true,
			};

			const gameSocket: Socket = io(API_URL, { ...connectionOptions, path: '/ws/games' });
			const tableSocket: Socket = io(API_URL, { ...connectionOptions, path: '/ws/tables' });
			const transactionSocket: Socket = io(API_URL, { ...connectionOptions, path: '/ws/transactions' });

			setSockets({ gameSocket, tableSocket, transactionSocket });

			gameSocket.on('connect_error', (err) => {
				console.log('Connection Error:', err.message);
			});

			return () => {
				gameSocket.disconnect();
				tableSocket.disconnect();
				transactionSocket.disconnect();
			};
		}
	}, [user]);

	return <SocketContext.Provider value={sockets}>{children}</SocketContext.Provider>;
};

export const useSocket = (): SocketsListInterface => {
	const context = useContext(SocketContext);
	if (context === undefined) {
		throw new Error('useSocket must be used within a SocketProvider');
	}
	return context;
};
