import { Dispatch, SetStateAction } from 'react';
import { Dayjs } from 'dayjs';

export interface DateRange {
	label?: string;
	startDate?: Dayjs;
	endDate?: Dayjs;
}

export type Setter<T> = Dispatch<SetStateAction<T>> | ((value: T) => void);

export enum NavigationAction {
	// eslint-disable-next-line no-unused-vars
	Previous = -1,

	// eslint-disable-next-line no-unused-vars
	Next = 1,
}

export type DefinedRange = {
	startDate: Dayjs;
	endDate: Dayjs;
	label: string;
};
