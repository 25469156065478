export default class WrapperService {
  isAndroid() {
    return !!window.messageHandlers;
  }

  isIOS() {
    return !!(window.webkit && window.webkit.messageHandlers);
  }

  notifyLogin(userId: string) {
    try {
      if (this.isIOS()) {
        window.webkit?.messageHandlers.login.postMessage({ userId });
      }
      if (this.isAndroid()) {
        window.messageHandlers.login(JSON.stringify({ userId }));
      }
    } catch (e) {
      console.error(e);
    }
  }

  notifyLogOut(userId?: string) {
    try {
      if (this.isIOS()) {
        window.webkit?.messageHandlers.logout.postMessage({ userId });
      }
      if (this.isAndroid()) {
        window.messageHandlers.logout(JSON.stringify({ userId }));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
