import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EmptyPage from "../core/empty.page";
import VerifyOTPComponent from "./verify-otp.component";
import SendOTPComponent from "./send-otp.component";
import PhoneFormat from "../core/helpers/phone-format.helper";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      textAlign: "center",
    },
    image: {
      width: "80%",
      marginTop: 40,
      height: "auto",
    },
    heading: {
      textAlign: "center",
      marginBottom: 50,
      marginTop: 40,
      fontSize: 22,
      "& small": {
        fontSize: 13,
        display: "inline-block",
        width: "100%",
      },
      "& b": {
        fontWeight: 500,
      },
    },
    headingButtons: {
      margin: "0 auto",
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 500,
      width: "100%",
    },
    bottomText: {
      fontSize: 12,
      color: "#ccc",
      textShadow: "none",
      "& a": {
        color: "#ccc",
      },
    },
  })
);

export default function SignInPhonePage() {
  const classes = useStyles({});
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [enterCodeVisible, setEnterCodeVisible] = useState<boolean>(false);

  const onOTPSend = ({ phone }: { phone: string }) => {
    setPhoneNumber(phone);
    setEnterCodeVisible(true);
  };

  const showPhonePage = () => {
    setEnterCodeVisible(false);
  };
  return (
    <EmptyPage>
      {enterCodeVisible && (
        <div className={classes.headingButtons}>
          <IconButton
            style={{ padding: 9 }}
            onClick={showPhonePage}
            aria-label="back"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        className={classes.container}
      >
        <Grid item sm={12} xs={12}>
          <img
            height="194"
            width="345"
            className={classes.image}
            src="/login.png"
            alt="Login"
          />
          {!enterCodeVisible && (
            <Typography className={classes.heading} variant="h1" gutterBottom>
              Enter your mobile number
              <small>We will send you a confirmation code</small>
            </Typography>
          )}

          {enterCodeVisible && (
            <Typography className={classes.heading} variant="h1" gutterBottom>
              Enter confirmation code
              <small>
                A 6-digit code was sent to <b>{PhoneFormat(phoneNumber)}</b>
              </small>
            </Typography>
          )}

          {!enterCodeVisible && <SendOTPComponent onSend={onOTPSend} />}
          {enterCodeVisible && (
            <VerifyOTPComponent
              initialValues={{ phone: phoneNumber, code: "" }}
            />
          )}
        </Grid>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <br />
            <Typography
              className={classes.bottomText}
              variant="body2"
              color="textSecondary"
              component="small"
            >
              By signing to the app, you are agreeing to our{" "}
              <a
                target="_blank"
                rel="noreferrer"
                title="Privacy Policy"
                href="/privacy-policy.html"
              >
                Privacy Policy
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </EmptyPage>
  );
}
