import TransactionType from '@homegame/common/dist/enum/transaction-type.enum';
import TransactionInterface from '@homegame/common/dist/interface/transaction.interface';

/**
 * The function filters an array of transactions based on the player's ID and the type of transaction.
 * @param {number} playerId - The playerId parameter is a number that represents the ID of a player.
 * @param {TransactionInterface[] | undefined} transactions - An array of transaction objects. Each
 * transaction object has the following properties:
 * @returns an array of TransactionInterface objects.
 */
export default function getPlayerTransactions(playerId: number, transactions: TransactionInterface[] | undefined): TransactionInterface[] {
	if (!transactions) return [];

	return transactions?.filter((transaction) => {
		return (
			(transaction.type === TransactionType.CASH_OUT && transaction.toUserId === playerId) ||
			(transaction.type === TransactionType.BUY_IN && transaction.fromUserId === playerId)
		);
	});
}
