import { FieldErrors } from "react-hook-form";

interface ParseErrorsConfig {
  fieldNames?: { [key: string]: string };
  customMessages?: { [key: string]: string };
}

/**
 * Parses validation errors and returns an array of formatted error messages.
 *
 * @param errors - The FieldErrors object containing validation errors.
 * @param config - Optional configuration for customizing field names and error messages.
 *   @param config.fieldNames - A mapping of field keys to friendly display names.
 *   @param config.customMessages - A mapping of field keys to custom error messages.
 * @returns An array of formatted error messages as strings.
 *
 * This function processes a nested FieldErrors object, extracting error messages
 * for each field. It supports custom field names and messages, and applies default
 * formatting for common validation errors. Nested errors are handled recursively.
 */
const parseErrors = (
  errors: FieldErrors,
  config: ParseErrorsConfig = {}
): string[] => {
  const { fieldNames = {}, customMessages = {} } = config;
  const messages: string[] = [];

  const getFriendlyName = (fieldName: string): string => {
    return fieldNames[fieldName] || fieldName.replace(/\[\d+]/g, "");
  };

  const formatMessage = (fieldName: string, message: string): string => {
    const friendlyName = getFriendlyName(fieldName);

    if (customMessages[fieldName]) {
      return customMessages[fieldName];
    }

    if (message.includes("must be a `number` type")) {
      return `Enter a valid number for "${friendlyName}".`;
    } else if (message.includes("required")) {
      return `Please provide a value for "${friendlyName}".`;
    } else if (message.includes("positive")) {
      return `"${friendlyName}" must be a positive number.`;
    } else if (message.includes("greater")) {
      return `"${friendlyName}" must be greater than the Minimum Buy-In.`;
    }

    return `${friendlyName}: ${message}`;
  };

  const extractMessages = (errorObj: FieldErrors, parentKey = "") => {
    for (const key in errorObj) {
      if (errorObj[key]) {
        const error = errorObj[key];
        const fieldName = parentKey ? `${parentKey}.${key}` : key;

        if (
          typeof error === "object" &&
          "message" in error &&
          typeof error.message === "string"
        ) {
          messages.push(formatMessage(fieldName, error.message));
        }

        if (typeof error === "object" && !("message" in error)) {
          extractMessages(error as FieldErrors, fieldName);
        }
      }
    }
  };

  extractMessages(errors);
  return messages;
};

export default parseErrors;
