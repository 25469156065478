import { Cookies } from "react-cookie";

interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
  encode?: (value: string) => string;
}

const getParams = (): CookieSetOptions => {
  const opts = {
    domain: "",
    path: "/",
    sameSite: false,
    httpOnly: false,
    expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
  };

  return opts;
};

export function getLocalStorage(name: string): any | undefined {
  const res = window.localStorage.getItem(name);
  if (res === null) return undefined;
  try {
    return JSON.parse(String(res));
  } catch (e) {
    return res;
  }
}

export function getSessionStorage(name: string): any | undefined {
  const res = window.sessionStorage.getItem(name);
  if (res === null) return undefined;

  try {
    return JSON.parse(String(res));
  } catch (e) {
    return res;
  }
}

export function deleteLocalStorage(name: string) {
  return window.localStorage.removeItem(name);
}

export function deleteSessionStorage(name: string) {
  return window.sessionStorage.removeItem(name);
}

export function setLocalStorage(name: string, value: any) {
  return window.localStorage.setItem(name, JSON.stringify(value));
}

export function setSessionStorage(name: string, value: any) {
  return window.sessionStorage.setItem(name, value);
}

export function getCookie(name: string): any | undefined {
  const cookiesIns = new Cookies();
  try {
    return JSON.parse(cookiesIns.get(name));
  } catch (e) {
    return cookiesIns.get(name);
  }
}

export function deleteCookie(name: string) {
  const cookiesIns = new Cookies();
  return cookiesIns.remove(name, getParams());
  // document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function setCookie(name: string, value: any) {
  const cookiesIns = new Cookies();
  return cookiesIns.set(name, value, getParams());
}
