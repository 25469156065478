import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const GameTabsStyles = makeStyles((theme) =>
  createStyles({
    tabBtn: {
      padding: "8px 8px",
      minWidth: 60,
      fontSize: 12,
      minHeight: 30,
    },
    baseContainer: {
      maxWidth: 500,
      width: "100%",
      padding: "20px 10px",
      marginTop: 10,
      gap: 10,
      height: "calc(100% - 80px)",
      textShadow: "none",
      borderRadius: 5,
      backgroundColor: "#aab1db",
      boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
      justifyContent: "center",
      alignItems: "center",
    },
    playersContainer: {
      display: "grid",
      gridTemplateRows: "1fr 1fr 1fr 1fr  1fr",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    statsContainer: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      overflowY: "auto",
    },
    canceledContainer: {
      height: 150,
      textAlign: "center",
      "& h6": {
        lineHeight: "100px",
      },
    },
    invitePlayerText: {
      textAlign: "center",
      fontSize: 12,
      "& small": {
        display: "inline-block",
      },
    },
    invitePlayerBtn: {
      position: "relative",
      flex: "0 0 31%",
      padding: 5,
      borderRadius: 5,
      boxShadow: "0 0 10px rgba(0,0,0,.5)",
      fontSize: 13,
      background: theme.palette.primary.main,
      "& .MuiAvatar-root": {
        margin: "5px auto",
        width: 40,
        height: 40,
        fontSize: 10,
      },
      "&:after": {
        content: '""',
        display: "block",
        paddingBottom: "10%",
        float: "left",
      },
    },
  })
);

export default GameTabsStyles;
