import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles(() =>
  createStyles({
    horizontalCardDescription: {
      color: "#47528c",
      textShadow: "none",
      fontSize: 13,
      textAlign: "center",
      padding: "0 10px",
    },
    horizontalCardIconWrap: {
      padding: 15,
      background: "rgba(255, 255, 255, 0.6)",
      borderRadius: 15,
      textAlign: "center",
      "& svg": {
        marginBottom: -3,
        color: "#fff",
      },
    },
    horizontalCardTitle: {
      textShadow: "none",
      textAlign: "center",
      fontSize: 26,
      fontWeight: 300,
      color: "#2c345c",
      padding: "0 10px",
    },
    horizontalCard: {
      marginBottom: 15,
    },
  })
);

interface CardHorizontalProps {
  icon: ReactNode;
  primary: ReactNode;
  secondary: ReactNode;
}

export default function CardHorizontalComponent({
  icon,
  primary,
  secondary,
}: CardHorizontalProps) {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <Grid item xs={2} className={classes.horizontalCardIconWrap}>
        {icon}
      </Grid>
      <Grid item xs={5} style={{ borderRight: "1px solid white" }}>
        <Typography className={classes.horizontalCardTitle}>
          {primary}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography className={classes.horizontalCardDescription}>
          {secondary}
        </Typography>
      </Grid>
    </Grid>
  );
}
