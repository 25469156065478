import GameTabsStyles from "./styles";
import PlayerIconComponent from "../player-icon.component";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AddToGameDialog from "../components/add-to-game.component";
import { useNavigate } from "react-router-dom";
import { useGame } from "../game.context";

export default function TabPlayers() {
  const {
    game,
    playersWithErrors,
    isCashier,
    isNew,
    isOngoing,
    isSuperAdmin,
    reloadGame,
  } = useGame();

  const navigate = useNavigate();
  const [invitePlayerOpened, setInvitePlayerOpened] = useState<boolean>(false);

  const classes = GameTabsStyles();

  if (!game) return <></>;

  const toggleInvitePlayer = async () => {
    setInvitePlayerOpened(!invitePlayerOpened);
  };

  const onInviteAddClose = async () => {
    setInvitePlayerOpened(false);
    await reloadGame();
    navigate(`/game/${game!.id}/players`);
  };

  return (
    <>
      <div className={`${classes.baseContainer} ${classes.playersContainer}`}>
        {game.players
          ?.sort((p1, p2) => p1.id! - p2.id!)
          .map((p, i) => (
            <PlayerIconComponent
              key={p.id}
              dialogsDisabled={!isCashier && !isSuperAdmin}
              error={playersWithErrors.includes(Number(p.playerId))}
              index={i}
              tablePlayer={p}
              table={game.table!}
              onPlayerStatusChange={reloadGame}
              onPlayerDelete={reloadGame}
              onTransactionSent={reloadGame}
            />
          ))}

        {isCashier && (isNew || isOngoing) && (
          <div className={classes.invitePlayerBtn} onClick={toggleInvitePlayer}>
            <Avatar sx={{ bgcolor: "transparent" }}>
              <SendIcon />
            </Avatar>
            <Typography
              className={classes.invitePlayerText}
              variant="body1"
              gutterBottom
            >
              Invite player
            </Typography>
          </div>
        )}
      </div>
      {(isNew || isOngoing) && (
        <AddToGameDialog
          open={invitePlayerOpened}
          type="invite"
          onClose={onInviteAddClose}
        />
      )}
    </>
  );
}
