import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth.context";
import React, { ReactNode, useEffect, useState } from "react";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Badge } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PersonIcon from "@mui/icons-material/Person";
import UserRole from "@homegame/common/dist/enum/user-role.enum";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import BottomNavigation from "@mui/material/BottomNavigation";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(63,68,117,0.2) 0%, rgba(63,68,117,0.8) 38%, rgba(63,68,117,1) 100%)",
    borderRadius: "20px 20px 0 0",
    zIndex: 999,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: 500,
    margin: "0 auto",
    height: 65,
    "& button": {
      color: "#fff",
      justifyContent: "flex-start",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
    "& .MuiBottomNavigationAction-root:first-child": {
      borderRadius: "20px 0 0 0",
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: "0.8rem",
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "0.8rem",
    },
    "& .MuiBottomNavigationAction-root:last-child": {
      borderRadius: "0 20px 0 0",
    },
    "& .MuiBottomNavigationAction-root.Mui-selected": {
      fontSize: "0.8rem",
      background:
        "linear-gradient(180deg, rgba(245,0,87,0.2) 0%, rgba(245,0,87,0.8) 38%, rgba(245,0,87,1) 100%)",
      color: "#fff",
    },
  },
  badge: {
    height: 30,
  },
}));

type RouteKeys = "profile" | "tables" | "transactions" | "stats" | "admin";
type Routes = Record<RouteKeys, string>;

const NavigationIcon = ({ icon }: { icon: ReactNode }) => (
  <Badge
    overlap="circular"
    color="secondary"
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    className={useStyles().badge}
  >
    <Avatar sx={{ bgcolor: "transparent" }}>{icon}</Avatar>
  </Badge>
);

export default function BottomNavigationComponent() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, logOut } = useAuth();

  const { pathname } = useLocation();
  const [value, setValue] = useState<RouteKeys | undefined>();

  const routes: Routes = {
    profile: "/profile",
    tables: "/tables",
    transactions: "/transactions",
    stats: "/stats",
    admin: "/admin",
  };

  useEffect(() => {
    Object.keys(routes).forEach((key) => {
      if (pathname.includes(key)) setValue(key as RouteKeys);
    });
  }, [pathname, routes]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: RouteKeys) => {
    setValue(newValue);
    if (newValue === "admin" || newValue === "profile") {
      if (user) navigate(routes[newValue]);
    } else {
      navigate(routes[newValue]);
    }
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      className={classes.root}
      id="bottomNavigation"
    >
      <BottomNavigationAction
        label="Tables"
        value="tables"
        icon={<NavigationIcon icon={<GroupWorkIcon />} />}
      />
      <BottomNavigationAction
        label="Payments"
        value="transactions"
        icon={<NavigationIcon icon={<AttachMoneyIcon />} />}
      />
      <BottomNavigationAction
        label="Stats"
        value="stats"
        icon={<NavigationIcon icon={<EqualizerIcon />} />}
      />
      <BottomNavigationAction
        label="Profile"
        value="profile"
        icon={<NavigationIcon icon={<PersonIcon />} />}
      />
      {user?.role.includes(UserRole.SUPERADMIN) && (
        <BottomNavigationAction
          label="Admin"
          value="admin"
          icon={<NavigationIcon icon={<AdminPanelSettingsIcon />} />}
        />
      )}
      <BottomNavigationAction
        label="Log out"
        onClick={logOut}
        icon={<NavigationIcon icon={<LogoutIcon />} />}
      />
    </BottomNavigation>
  );
}
