import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const TableViewStyle = makeStyles(() =>
	createStyles({
		container: {
			maxWidth: 500,
			margin: '0 auto',
			height: '100%',
			alignContent: 'flex-start',
		},
		subpos: {
			marginBottom: 0,
			color: '#fff',
			fontWeight: 400,
			fontSize: 15,
		},
		iconBtn: {
			float: 'right',
			padding: 5,
			// color: '#fff',
			marginRight: 15,
			marginLeft: 15,
		},
		heading: {
			fontWeight: 500,
			fontSize: 19,
			paddingLeft: 40,
			paddingRight: 40,
			textAlign: 'center',
			position: 'relative',
			borderBottom: '2px solid rgba(245,0,87,.6)',
			paddingBottom: 7,
		},
		cardActions: {
			flexDirection: 'row',
			'& .MuiChip-label ': {
				color: '#fff',
			},
		},
		cardRoot: {
			width: '100%',
			marginBottom: 10,
			backgroundColor: '#4b5170',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			textDecoration: 'none',
		},
	})
);

export default TableViewStyle;
