import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { red } from "@mui/material/colors";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import { useAuth } from "../core/context/auth.context";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import GamePlayerInterface from "@homegame/common/dist/interface/game-player.interface";
import GameInfoPlayer from "./game-info-player";
import PlayerStatusComponent from "./player-status.component";
import getPlayerLastTransaction from "../core/hooks/useGameInfo/get-player-last-transaction";
import { useGame } from "./game.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: 40,
      height: 40,
      fontSize: 10,
    },
    youChip: {
      position: "absolute",
      top: -5,
      left: -5,
      zIndex: 999,
      background: "rgba(245,0,87,.6)",
      padding: 0,
      height: 15,
      color: "white",
      "& .MuiChip-label ": {
        fontSize: 9,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    cashierChip: {
      position: "absolute",
      bottom: -5,
      right: 0,
      left: 0,
      margin: "0 auto",
      width: 75,
      zIndex: 999,
      background: "green",
      padding: 0,
      height: 15,
      color: "white",
      "& .MuiSvgIcon-root ": {
        color: "#fff",
        fontSize: 8,
        marginRight: -2,
      },
      "& .MuiChip-label ": {
        fontSize: 9,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    playerName: {
      textAlign: "center",
      fontSize: 12,
      "& small": {
        display: "inline-block",
      },
    },
    avatar_error: {
      backgroundColor: red[500],
      color: theme.palette.getContrastText(red[500]),
    },
    textError: {
      color: red[500],
      textShadow: `0 0 0 ${theme.palette.getContrastText(red[500])}`,
      textAlign: "center",
      fontSize: 12,
      "& small": {
        display: "inline-block",
      },
    },
    tablePlayer: {
      position: "relative",
      flex: "0 0 31%",
      // width: '33%',
      padding: 5,
      borderRadius: 5,
      boxShadow: "0 0 10px rgba(0,0,0,.5)",
      fontSize: 13,
      background: theme.palette.primary.main,
      "& .MuiAvatar-root": {
        margin: "5px auto",
        width: 40,
        height: 40,
        fontSize: 10,
      },
      "&:after": {
        content: '""',
        display: "block",
        paddingBottom: "10%",
        float: "left",
      },
    },
    tablePlayer_disabled: {
      "& .MuiAvatar-root": {
        backgroundColor: "#ccc",
      },
      "& .MuiTypography-root, & .MuiAvatar-root": {
        color: "white",
      },
    },
    tablePlayer_pending: {
      "& .MuiAvatar-root, & p": {},
    },
  })
);

export default function PlayerIconComponent({
  table,
  index,
  tablePlayer,
  dialogsDisabled = false,
  onPlayerDelete,
  onPlayerStatusChange,
  error = false,
  onTransactionSent,
}: {
  error?: boolean;
  dialogsDisabled?: boolean;
  index: number;
  table: TableInterface;
  onPlayerDelete?: () => void;
  onPlayerStatusChange?: () => void;
  onTransactionSent?: () => void;
  tablePlayer: GamePlayerInterface;
}) {
  const { game, isCanceled } = useGame();
  const classes = useStyles();
  const { user } = useAuth();
  const [playerOptionsVisible, setPlayerOptionsVisible] =
    useState<boolean>(false);

  if (!user || !game) return <></>;

  const playerName = tablePlayer.player
    ? tablePlayer.player.name
    : tablePlayer.phone;

  const lastPlayerTransaction = getPlayerLastTransaction(
    tablePlayer.playerId!,
    game.transactions
  );

  const playerCashedOut = lastPlayerTransaction
    ? lastPlayerTransaction.type === TransactionType.CASH_OUT
    : false;

  const closeActions = () => {
    setPlayerOptionsVisible(false);
  };

  const openActions = () => {
    if (dialogsDisabled) return;
    if (!isCanceled) setPlayerOptionsVisible(true);
  };

  const handlePlayerDelete = async () => {
    closeActions();
    await onPlayerDelete?.();
  };

  // @ts-ignore
  const playerClassName = classes["tablePlayer_" + (index + 1)];

  const wrapperClassName = `${classes.tablePlayer} ${playerClassName} ${
    error ? "tablePlayer_error" : ""
  } ${playerCashedOut ? classes.tablePlayer_disabled : ""}`;

  return (
    <>
      <div className={wrapperClassName} onClick={openActions}>
        {table && tablePlayer.playerId === game.cashierId && (
          <Chip className={classes.cashierChip} label="Cashier" />
        )}
        {tablePlayer.playerId === user.id && (
          <Chip className={classes.youChip} label="You" />
        )}
        <PlayerStatusComponent table={table} gamePlayer={tablePlayer} />
        <Avatar className={`avatar ${error ? classes.avatar_error : ""}`} />

        <Typography
          className={error ? classes.textError : classes.playerName}
          variant="body1"
          gutterBottom
        >
          {playerName}
        </Typography>
      </div>

      <Dialog open={playerOptionsVisible} onClose={closeActions}>
        <DialogContent style={{ backgroundColor: "#aab1db" }}>
          <GameInfoPlayer
            isDialog
            user={tablePlayer.player}
            onCloseBtnClick={closeActions}
            onTransactionSent={onTransactionSent}
            onPlayerDelete={handlePlayerDelete}
            onPlayerStatusChange={onPlayerStatusChange}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
