import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableInterface from "@homegame/common/src/interface/table.interface";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../core/context/auth.context";
import TableService from "../core/service/table.service";
import { Link } from "react-router-dom";
import HeadingComponent from "../core/components/heading.component";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useToast } from "../core/hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    headingBtns: {
      "&.Mui-disabled": {
        opacity: 0.3,
      },
    },
  })
);

export default function TableForm({
  initialValues,
  onSave,
}: {
  initialValues?: TableInterface | undefined;
  onSave: (table: TableInterface) => void;
}) {
  const { showToast } = useToast();
  const classes: any = useStyles({});
  const tableService = new TableService();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { user } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  const onSubmit = async (userData: any) => {
    try {
      if (!user) return;
      const newData = userData;

      newData.userId = user.id;
      setIsSaving(true);

      const savedData = await tableService.save(newData, initialValues?.id);
      onSave(savedData);
      showToast("Table saved", "success");
    } catch (e: any) {
      let errorMsg = e.message;

      if (e.data && e.data.length) {
        e.data.forEach(({ msg, param }: { msg: string; param: string }) => {
          errorMsg += `. ${param}: ${msg}`;
        });
      }
      showToast(errorMsg, "error");
    } finally {
      setIsSaving(false);
    }
  };

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="flex-start"
        maxWidth={500}
        margin="0 auto"
      >
        <Grid item xs={12}>
          <HeadingComponent text={initialValues ? "Edit table" : "Add table"} />
          <div className="heading-buttons">
            <IconButton
              className={classes.headingBtns}
              type="submit"
              aria-label="add"
              size="large"
              disabled={isSaving}
              data-test-id="saveTable"
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              className={classes.headingBtns}
              disabled={isSaving}
              component={Link}
              to={`/tables/${initialValues ? initialValues.id + "/view" : ""}`}
              aria-label="back"
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Typography>Table Name</Typography>
              <FormControl fullWidth style={{ display: "inline-block" }}>
                {/* @ts-ignore*/}
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.name ? errors.name.message : ""}
                      error={!!errors.name}
                      fullWidth
                      disabled={isSaving}
                      variant="standard"
                      {...field}
                    />
                  )}
                  name="name"
                  rules={{
                    required: "Name required!",
                    minLength: {
                      value: 3,
                      message: "Min name length is 3",
                    },
                  }}
                  control={control}
                  defaultValue={initialValues?.name}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
