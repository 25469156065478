import React, { createContext, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import dayjsutc from "dayjs/plugin/utc";
import jwt_decode from "jwt-decode";
import {
  getUserDataHelper,
  resetAuthHelper,
  setUserDataHelper,
} from "../helpers/auth.helper";
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
  getLocalStorage,
  setLocalStorage,
} from "../helpers/storage.helper";
import UserService from "../service/user.service";
import UserInterface from "@homegame/common/src/interface/user.interface";
import LoadingComponent from "../components/loading.component";
import { REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME } from "../config";
import ipLookUpHelper from "../helpers/ip-lookup.helper";
import { GeolocationData } from "../helpers/geolocate.helper";
import { toast } from "react-toastify";

dayjs.extend(dayjsutc);

interface AuthContextInterface {
  user: UserInterface | undefined;
  userLocation: GeolocationData | undefined;
  logOut: () => Promise<void>;
  updateAuthData: () => Promise<void>;
  setUser: (user: UserInterface) => void;
}

const defaultAuthContext: AuthContextInterface = {
  user: undefined,
  userLocation: undefined,
  logOut: async () => {},
  updateAuthData: async () => {},
  setUser: () => {},
};

export const AuthContext =
  createContext<AuthContextInterface>(defaultAuthContext);

export const AuthContextProvider = ({ children }: { children: any }) => {
  const userService = new UserService();
  const [userLocation, setUserLocation] = useState<GeolocationData>();

  const [user, setUser] = useState<UserInterface>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const geolocate = async (): Promise<void> => {
    const ipLocation = await ipLookUpHelper();

    if (ipLocation) {
      const clone = { ...ipLocation };
      setUserLocation(clone);
    }
  };

  const doGeolocation = async () => {
    try {
      const data = getLocalStorage("userLocation");
      let obj;
      if (data && typeof data === "object") obj = data;
      if (data && typeof data === "string") obj = JSON.parse(data);
      if (!obj.country) throw new Error("wrong cache item");
      setUserLocation(obj);
      return obj;
    } catch (e) {
      return await geolocate();
    }
  };

  useEffect(() => {
    Promise.all([doGeolocation(), updateAuthData()]).then();
  }, []);

  const redirectToSignIn = async (errorMessage?: string) => {
    setLoaded(true);
    if (errorMessage) {
      toast.error(errorMessage);
      console.error(errorMessage);
    }
    await logOut();
  };

  const updateAuthData = async () => {
    try {
      const accessToken = getCookie(TOKEN_COOKIE_NAME);

      if (!accessToken) {
        await logOut();
        setLoaded(true);
        return;
      }

      const decodedToken: { id?: number } = jwt_decode(accessToken);

      if (!decodedToken?.id) {
        await redirectToSignIn("Expired user access data");
        return;
      }

      let userData = getUserDataHelper();

      if (
        !userData ||
        typeof userData !== "object" ||
        userData.id !== decodedToken.id
      ) {
        try {
          userData = await userService.single(decodedToken.id);
          setUser(userData);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          toast.error((error as Error).message);
          await logOut();
          return;
        }
      } else {
        setUser(userData);
      }

      setLoaded(true);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  };

  const logOut = async () => {
    try {
      deleteLocalStorage("user");
      deleteCookie(TOKEN_COOKIE_NAME);
      deleteCookie(REFRESH_TOKEN_COOKIE_NAME);
      setUser(undefined);
      await resetAuthHelper();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  };

  useEffect(() => {
    if (!user) return;
    setUserDataHelper(user);
    setLoaded(true);
  }, [user]);

  useEffect(() => {
    if (userLocation && typeof userLocation.country !== "undefined") {
      setLocalStorage("userLocation", userLocation);
    }
  }, [userLocation]);

  const value = {
    user,
    userLocation,
    logOut,
    setUser,
    updateAuthData,
  };

  if (loaded) {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  return <LoadingComponent />;
};

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
