import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useLoader } from '../core/context/loader.context';
import Typography from '@mui/material/Typography';
import UserService from '../core/service/user.service';
import UserInterface from '@homegame/common/dist/interface/user.interface';

const useStyles = makeStyles(() =>
	createStyles({
		table: {
			paddingTop: 30,
		},
		subpos: {
			marginBottom: 0,
			color: '#fff',
			fontWeight: 500,
			fontSize: 14,
		},
		chipPlus: {
			height: 23,
			fontWeight: 'bold',
			color: 'green',
			backgroundColor: '#c3e9c3',
		},
		chipMinus: {
			height: 23,
			fontWeight: 'bold',
			color: 'red',
		},
		cardRoot: {
			backgroundColor: '#47528c',
		},
		userName: {
			fontSize: 12,
		},
	})
);

export default function UsersListComponent() {
	const classes = useStyles({});
	const userService = new UserService();
	const { showLoader, hideLoader } = useLoader();
	const [users, setUsers] = useState<UserInterface[]>([]);

	const loadUsers = useCallback(async () => {
		try {
			showLoader();
			const [t] = await userService.list();
			setUsers(t);
		} catch (e) {
			console.error(e);
		} finally {
			hideLoader();
		}
	}, []);

	useEffect(() => {
		loadUsers().then();
	}, []);

	return (
		<Grid item xs={12} style={{ paddingLeft: 0 }}>
			{users.length === 0 && <Typography align="center">No users</Typography>}
			{users.length > 0 && (
				<TableContainer className={classes.table}>
					<Table aria-label="users table">
						<TableBody>
							{users.map((u) => (
								<TableRow key={u.id} className={classes.cardRoot}>
									<TableCell className={classes.subpos}>
										<Typography className={classes.userName}>
											<b>{u.name}</b>
										</Typography>
									</TableCell>
									<TableCell className={classes.subpos}>{u.phone}</TableCell>
									<TableCell align="right">
										<Typography className={classes.userName}>
											<b>{u.role.toString()}</b>
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Grid>
	);
}
