import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import PhoneInputComponent from "../core/components/phone-input.component";
import React, { useEffect, useState } from "react";
import UserService from "../core/service/user.service";
import { usePullToRefresh } from "../core/context/pull-to-refresh.context";
import { useAuth } from "../core/context/auth.context";
import { useToast } from "../core/hooks/use-toast.hook";
import { useMutation, useQuery } from "@tanstack/react-query";
import HeadingComponent from "../core/components/heading.component";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import { AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

export default function ProfileForm() {
  const userService = new UserService();
  const { setRefreshFunction } = usePullToRefresh();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { updateAuthData, user, logOut } = useAuth();
  const { showToast } = useToast();

  const {
    data,
    refetch,
    isFetching: isSaving,
  } = useQuery({
    queryKey: [`singleUserData`, { userId: user!.id }],
    queryFn: () => userService.single(user!.id),
    initialData: undefined,
  });

  useEffect(() => {
    const refreshData = async () => {
      await refetch();
    };
    setRefreshFunction(refreshData);
    return () => {
      setRefreshFunction();
    };
  }, [setRefreshFunction]);

  const { handleSubmit, control } = useForm({ values: data });

  const { mutate } = useMutation({
    mutationFn: (values: any) => userService.save(values, user!.id),
    onError: (e: any) => {
      let errorMsg = e.message;

      if (e.data && e.data.length) {
        e.data.forEach(({ msg, param }: { msg: string; param: string }) => {
          errorMsg += `. ${param}: ${msg}`;
        });
      }
      showToast(errorMsg, "error");
    },
    onSuccess: async () => {
      await updateAuthData();
      showToast("Profile saved!", "success");
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const doLogout = () => {
    navigate(`/sign?userId=${user?.id}&action=logout`);
    return logOut();
  };

  return (
    <form onSubmit={handleSubmit(mutate)} style={{ width: "100%" }}>
      <Grid item xs={12}>
        <HeadingComponent text="Edit profile" />
        <div className="heading-buttons">
          <IconButton type="submit" aria-label="delete" size="large">
            <SaveIcon />
          </IconButton>
          <IconButton onClick={doLogout} aria-label="exit" size="large">
            <ExitToAppIcon />
          </IconButton>
          <IconButton
            component={Link}
            to="/tables/"
            aria-label="back"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </Grid>
      {isSaving && <LinearProgress />}
      <Grid item sm={12} xs={12} marginTop={5}>
        <br />
        <Typography>User Name</Typography>
        <FormControl fullWidth style={{ display: "inline-block" }}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                variant="filled"
                disabled={isSaving}
                {...field}
              />
            )}
            name="name"
            control={control}
            defaultValue={data?.name}
          />
        </FormControl>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Typography>Phone</Typography>
        <FormControl fullWidth>
          <Controller
            name="phone"
            control={control}
            defaultValue={data?.phone || ""}
            render={({ field }) => (
              <PhoneInputComponent
                autofocus={false}
                disabled={isSaving}
                {...field}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <br />

        <Button
          style={{
            color: "#ddd",
            textShadow: "none",
            fontSize: 12,
            left: 15,
            bottom: 100,
            position: "absolute",
          }}
          onClick={handleClickOpen}
        >
          Delete my account
        </Button>
      </Grid>

      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Delete my account
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          height="100%"
          title="Delete my account"
          frameBorder="none"
          src="https://docs.google.com/forms/d/e/1FAIpQLSevisU8eHZMNqVHlql03TrKLc08_jOMDrU_db8EcEmMk-pQQw/viewform"
        />
      </Dialog>
    </form>
  );
}
