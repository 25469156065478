import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import TransactionsListComponent from "../../transactions/transactions-list.component";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import GameTabsStyles from "./styles";

export default function TabTransactions({
  game,
  type = TransactionType.BUY_IN,
  reloadGame,
}: {
  type: TransactionType;
  reloadGame: () => any;
  game: GameInterface;
}) {
  const classes = GameTabsStyles();

  return (
    <div className={`${classes.baseContainer}`}>
      <TransactionsListComponent
        height="calc(100svh - 275px)"
        game={game}
        type={type}
        showStatus
        onActionDone={reloadGame}
      />
    </div>
  );
}
