import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { chunks, getDaysInMonth, isStartOfRange, isEndOfRange, inDateRange, isRangeSameDay } from '../utils';
import Header from './Header';
import Day from './Day';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import { NavigationAction, DateRange } from '../types';
import dayjs, { Dayjs } from 'dayjs';

dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(isBetween);

const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: 300,
			margin: '0 auto',
		},
		weekDaysContainer: {
			marginTop: 10,
			paddingLeft: 20,
			paddingRight: 20,
		},
		daysContainer: {
			// paddingLeft: 15,
			// paddingRight: 15,
			marginTop: 15,
			marginBottom: 20,
		},
	})
);

interface MonthProps {
	value: Dayjs;
	marker: symbol;
	dateRange: DateRange;
	minDate: Dayjs;
	maxDate: Dayjs;
	navState: [boolean, boolean];
	setValue: (date: Dayjs) => void;
	helpers: {
		inHoverRange: (day: Dayjs) => boolean;
	};
	handlers: {
		onDayClick: (day: Dayjs) => void;
		onDayHover: (day: Dayjs) => void;
		onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
	};
}

const Month: React.FunctionComponent<MonthProps> = (props: MonthProps) => {
	const classes = useStyles();
	const { helpers, handlers, value: date, dateRange, marker, setValue: setDate, minDate, maxDate } = props;
	const [back, forward] = props.navState;

	return (
		<Paper square elevation={0} className={classes.root}>
			<Grid container>
				<Header
					date={date}
					setDate={setDate}
					nextDisabled={!forward}
					prevDisabled={!back}
					onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
					onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
				/>

				<Grid item container direction="row" justifyContent="space-between" className={classes.weekDaysContainer}>
					{WEEK_DAYS.map((day) => (
						<Typography color="white" key={day} variant="caption">
							{day}
						</Typography>
					))}
				</Grid>

				<Grid item container direction="column" justifyContent="space-between" className={classes.daysContainer}>
					{chunks(getDaysInMonth(date), 7).map((week, idx) => (
						<Grid key={idx} container direction="row" justifyContent="center">
							{week.map((day) => {
								const isStart = isStartOfRange(dateRange, day);
								const isEnd = isEndOfRange(dateRange, day);
								const isRangeOneDay = isRangeSameDay(dateRange);
								const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);

								return (
									<Day
										key={day.format('MM-DD-YYYY')}
										filled={isStart || isEnd}
										outlined={day.isToday()}
										highlighted={highlighted && !isRangeOneDay}
										disabled={!date.isSame(day, 'month') || !day.isBetween(minDate, maxDate)}
										startOfRange={isStart && !isRangeOneDay}
										endOfRange={isEnd && !isRangeOneDay}
										onClick={() => handlers.onDayClick(day)}
										onHover={() => handlers.onDayHover(day)}
										value={day.date()}
									/>
								);
							})}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Month;
