import React, { forwardRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CURRENCY_LIST } from '@homegame/common/dist/lib/currency.lib';

interface SelectCurrencyProps {
	initialValue?: string | '';
	disabled?: boolean;
	error?: any;
	value: string;
}

const SelectCurrency = forwardRef(({ disabled = false, error, value, ...rest }: SelectCurrencyProps, ref) => {
	const [inputValue, setInputValue] = useState(value || '');
	const options: string[] = CURRENCY_LIST.map((i) => i.code).sort();

	return (
		<Autocomplete
			id="select-currency"
			{...rest}
			disableClearable
			disablePortal
			options={options}
			ref={ref}
			value={inputValue}
			onChange={(e, data) => {
				setInputValue(data);
				// @ts-ignore
				if (rest.onChange) rest.onChange(data);
			}}
			disabled={disabled}
			renderInput={(params) => (
				<TextField
					variant="standard"
					{...params}
					{...rest}
					fullWidth
					error={error}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			)}
		/>
	);
});

export default SelectCurrency;
