import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { FilledTextFieldProps } from "@mui/material/TextField/TextField";

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      margin: 0,
      "& .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        display: "none",
      },
      "& .MuiInputBase-input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& .MuiInputBase-input": {
        padding: 5,
        fontSize: 14,
        textAlign: "center",
        border: "none",
        outline: "none",
      },
    },
    button: {
      width: 27,
      height: 27,
      fontSize: 22,
      textAlign: "center",
      padding: "0 15px",
    },
  })
);

interface IntInputProps extends Omit<FilledTextFieldProps, "onChange"> {
  value: number | null | undefined;
  label?: string;
  name?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  showLabel?: boolean;
  error?: boolean;
  autofocus?: boolean;
  className?: string;
  onChange: (int: number | string) => void;
}

const IntInputComponent = forwardRef(
  (
    {
      onChange,
      label,
      min = 0,
      max = 999,
      value: initialValue,
      ...props
    }: IntInputProps,
    ref
  ) => {
    const classes: any = useStyles({});

    const [value, setValue] = useState<number | string>(initialValue ?? "");

    useEffect(() => {
      onChange(value);
    }, [value, onChange]);

    const handleIncrement = () => {
      if (typeof value !== "undefined" && Number(value) >= max) return;
      setValue((prevValue: number | string) => (Number(prevValue) ?? 0) + 1);
    };

    const handleDecrement = () => {
      if (typeof value !== "undefined" && Number(value) <= min) return;
      setValue((prevValue: number | string) => (Number(prevValue) ?? 0) - 1);
    };

    const handleChange = (e: ChangeEvent<{}>) => {
      if ((e.target as HTMLButtonElement).value.length > 0)
        setValue(Number((e.target as HTMLButtonElement).value));
      else setValue("");
    };

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={handleDecrement}
          disabled={props.disabled}
          className={classes.button}
        >
          -
        </IconButton>
        <TextField
          label={label}
          className={classes.input}
          // type="number"
          value={value}
          onChange={handleChange}
          inputRef={ref}
          {...props}
          sx={{ mx: 1 }}
        />
        <IconButton
          onClick={handleIncrement}
          disabled={props.disabled}
          className={classes.button}
        >
          +
        </IconButton>
      </Box>
    );
  }
);

export default IntInputComponent;
