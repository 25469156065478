import CssBaseline from "@mui/material/CssBaseline";
import { SnackBarProvider } from "./context/snack-bar.context";
import { LoaderProvider } from "./context/loader.context";
import Router from "./main.routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      throwOnError: true,
      networkMode: "always",
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LoaderProvider>
        <SnackBarProvider>
          <CssBaseline />
          <ToastContainer theme="dark" stacked position="bottom-center" />
          <Router />
        </SnackBarProvider>
      </LoaderProvider>
    </QueryClientProvider>
  );
}

export default App;
