import TableInterface from "@homegame/common/src/interface/table.interface";
import BaseService from "./base.service";
import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";

export default class TableService extends BaseService {
  baseEndpoint = "tables";

  async list(params?: any): Promise<[TableInterface[], number]> {
    return this.baseList(params);
  }

  async single(id: string | number | undefined): Promise<TableInterface> {
    return this.baseSingle<TableInterface>(id);
  }

  async save(
    data: Partial<TableInterface>,
    id: number | string | undefined | null
  ): Promise<TableInterface> {
    if (id) {
      try {
        return this.baseSave<TableInterface>(id, data);
      } catch (e) {
        console.error(e);
        throw BaseService.handleError(e);
      }
    }
    return this.baseCreate<TableInterface>(data);
  }

  async delete(id: string | number | undefined): Promise<TableInterface> {
    return this.baseDelete(id);
  }

  async deletePlayer(
    tableId: string | number | undefined,
    tablePlayerId: string | number | undefined
  ): Promise<TableInterface> {
    return this.request({
      method: "delete",
      url: `${this.baseEndpoint}/${tableId}/players/${tablePlayerId}`,
    });
  }

  async invitePlayer(
    tableId: string | number | undefined,
    phone: string
  ): Promise<TableInterface> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${tableId}/invite`,
      data: { phone },
    });
  }

  acceptInvite(tableId: string | number, phone: string) {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${tableId}/invite/accept`,
      data: { phone },
    });
  }

  declineInvite(tableId: string | number, phone: string) {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${tableId}/invite/decline`,
      data: { phone },
    });
  }

  getSingleInvite(inviteId: string | number): Promise<TablePlayerInterface> {
    return this.request({
      method: "get",
      url: `${this.baseEndpoint}/0/invite/${inviteId}`,
    });
  }

  resendInvite(
    inviteId: string | number,
    tableId: string | number
  ): Promise<TablePlayerInterface> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${tableId}/invite/${inviteId}/resend`,
    });
  }

  // todo
  deleteInvite() {}
}
