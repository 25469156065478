import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const TableListStyle = makeStyles(() =>
	createStyles({
		wrapper: {
			padding: 10,
			flexDirection: 'column',
			height: '100%',
			'& .MuiDataGrid-root': {
				border: 'none',
			},
			'& .MuiDataGrid-columnHeaders': {
				display: 'none',
			},
			'& .MuiDataGrid-footerContainer': {
				border: 'none',
			},
			'& .MuiTablePagination-displayedRows ': {
				border: 'none',
			},

			'& .MuiDataGrid-row': {
				backgroundColor: '#47528c',
				paddingTop: 10,
				paddingBottom: 10,
				borderRadius: 4,
				boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
				'& .MuiTypography-root': {
					whiteSpace: 'break-spaces',
				},
				'& .MuiDataGrid-cell': {
					border: 'none',
				},
				'& .actions-cell': {
					justifyContent: 'end',
				},
			},
		},
		loader: {
			display: 'block',
		},
		field: {
			width: '90%',
			marginLeft: '5%',
		},
		button: {
			width: '50%',
			marginLeft: '25%',
			marginTop: 30,
		},
		iconBtn: {
			float: 'right',
			padding: 5,
		},
		pos: {
			marginBottom: 0,
			fontSize: 17,
			color: '#fff',
			fontWeight: 500,
		},
		subpos: {
			marginBottom: 0,
			color: '#fff',
			fontSize: 15,
		},
		cardActions: {
			flexDirection: 'row',
		},
		cardRoot: {
			width: '100%',
			marginBottom: 10,
			backgroundColor: '#47528c',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			'& .MuiCardContent-root': {
				// paddingBottom: 5,
			},
			// borderRadius: 20,
		},
	})
);

export default TableListStyle;
