import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useDeferredValue,
} from "react";
import ReactPullToRefresh from "react-pull-to-refresh";
import SouthIcon from "@mui/icons-material/South";
import SyncIcon from "@mui/icons-material/Sync";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useLocation } from "react-router-dom";

interface PullToRefreshContextInterface {
  setRefreshFunction: (refreshFunction?: () => Promise<void>) => void;
  onRefresh?: () => Promise<void>;
}

const PullToRefreshContext = createContext<
  PullToRefreshContextInterface | undefined
>(undefined);

interface PullToRefreshProviderProps {
  children: ReactNode;
}

interface PullToRefreshContextInterface {
  setRefreshFunction: (refreshFunction?: () => Promise<void>) => void;
  onRefresh?: () => Promise<void>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    refreshContainer: {
      height: "100%",
      width: "100%",
    },
  })
);

const PullToRefreshProvider: React.FC<PullToRefreshProviderProps> = ({
  children,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [onRefresh, setOnRefresh] = useState<() => Promise<void>>();
  const deferredQuery = useDeferredValue(location.key);

  useEffect(() => {
    if (location.key !== deferredQuery) {
      setOnRefresh(undefined);
    }
  }, [location]);

  const setRefreshFunction = useCallback((fn?: () => Promise<void>) => {
    if (typeof fn === "function") {
      setOnRefresh(() => fn);
    }
  }, []);

  return (
    <PullToRefreshContext.Provider value={{ setRefreshFunction, onRefresh }}>
      {onRefresh ? (
        <ReactPullToRefresh
          className={classes.refreshContainer}
          onRefresh={onRefresh}
          icon={<SouthIcon />}
          loading={<SyncIcon />}
        >
          {children}
        </ReactPullToRefresh>
      ) : (
        children
      )}
    </PullToRefreshContext.Provider>
  );
};

const usePullToRefresh = (): PullToRefreshContextInterface => {
  const context = useContext(PullToRefreshContext);
  if (!context) {
    throw new Error(
      "usePullToRefresh must be used within a PullToRefreshProvider"
    );
  }
  return context;
};

export { PullToRefreshContext, PullToRefreshProvider, usePullToRefresh };
