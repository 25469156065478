import SystemSnackbarComponent from "../core/components/system-snackbar.component";
import SystemLoaderComponent from "../core/components/system-loader.component";
import ContentComponent from "../core/components/content.component";

export default function BasePage({ children }: any) {
  return (
    <>
      <ContentComponent>{children}</ContentComponent>
      <SystemSnackbarComponent />
      <SystemLoaderComponent />
    </>
  );
}
