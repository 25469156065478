/* eslint no-console: 0 */
import axios from "axios";
import {
  API_URL,
  REFRESH_TOKEN_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
} from "./config";
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
} from "./helpers/storage.helper";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: API_URL,
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

function isOnline() {
  return navigator.onLine;
}

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getCookie(TOKEN_COOKIE_NAME);
    if (accessToken) {
      config.headers!.auth = accessToken as string;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (!isOnline()) {
      return Promise.reject(new Error("No internet connection"));
    }

    if (error.response && error.response.status === 401) {
      const originalConfig = error.config;
      if (!originalConfig._retry) {
        originalConfig._retry = true;
        originalConfig._retryCount = (originalConfig._retryCount || 0) + 1;
        if (originalConfig._retryCount <= 3) {
          try {
            await axiosInstance.post("auth/refresh-token");
            return axiosInstance(originalConfig);
          } catch (refreshError) {
            toast.error("Session expired. Please login again.");

            deleteLocalStorage("user");
            deleteCookie(TOKEN_COOKIE_NAME);
            deleteCookie(REFRESH_TOKEN_COOKIE_NAME);

            window.location.href = "/sign";

            return Promise.reject(refreshError);
          }
        }
      }
      toast.error("Authentication error. Redirecting to login.");

      deleteLocalStorage("user");
      deleteCookie(TOKEN_COOKIE_NAME);
      deleteCookie(REFRESH_TOKEN_COOKIE_NAME);
      window.location.href = "/sign";
    }

    const errorMessage = error.response
      ? error.response?.data?.message
      : error.message;

    toast.error(errorMessage, { toastId: errorMessage });
    return Promise.reject(error);
  }
);

export { axiosInstance as axios };
