import GamePlayerInterface from '@homegame/common/dist/interface/game-player.interface';
import TransactionInterface from '@homegame/common/dist/interface/transaction.interface';
import GamePlayerStats from './interface/game-player-balance.interface';
import TransactionType from '@homegame/common/dist/enum/transaction-type.enum';
import TransactionStatus from '@homegame/common/dist/enum/transaction-status.enum';

export default function computePlayerMetrics(player: GamePlayerInterface, transactions: TransactionInterface[], gameRate: number): GamePlayerStats {
	let totalCashoutChips = 0;
	let totalCashoutMoney = 0;
	let totalBuyInChips = 0;
	let totalBuyInMoney = 0;
	let amountWonLost = 0;
	let debtPaymentsMade = 0;
	let paymentsReceived = 0;
	let totalDebt = 0;
	let payedDebt = 0;
	let receivedDebt = 0;

	const currentPlayerId = player.playerId;

	for (const { type, fromUserId, toUserId, chips, amount, status } of transactions) {
		if (status !== TransactionStatus.APPROVED) continue;

		switch (type) {
			case TransactionType.BUY_IN:
				if (fromUserId === currentPlayerId) {
					totalBuyInChips += chips;
					totalBuyInMoney += amount;
					amountWonLost -= chips;
				}
				break;
			case TransactionType.CASH_OUT:
				if (toUserId === currentPlayerId) {
					totalCashoutChips += chips;
					totalCashoutMoney += amount;
					amountWonLost += chips;
				}
				break;
			case TransactionType.PAYMENT:
				if (toUserId === currentPlayerId) {
					paymentsReceived += amount;
				}
				if (fromUserId === currentPlayerId) {
					debtPaymentsMade += amount;
				}
				break;
		}
	}

	amountWonLost *= gameRate;
	let debtToTable = (totalDebt = totalCashoutMoney - totalBuyInMoney - amountWonLost);
	payedDebt = debtPaymentsMade;
	receivedDebt = paymentsReceived;

	// Adjusting the debtToTable based on payments made or received
	debtToTable += debtToTable > 0 ? -debtPaymentsMade : paymentsReceived;

	return {
		id: player.playerId as number,
		name: player.player?.name || '',
		debtToTable,
		totalCashout: {
			chips: totalCashoutChips,
			money: totalCashoutMoney,
		},
		totalBuyIn: {
			chips: totalBuyInChips,
			money: totalBuyInMoney,
		},
		amountWonLost,
		totalDebt,
		payedDebt,
		receivedDebt,
	};
}
