import { useEffect, useMemo, useState, SetStateAction } from 'react';
import debouce from 'lodash.debounce';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import BasePage from '../core/base.page';
import { Link } from 'react-router-dom';
import HeadingComponent from '../core/components/heading.component';
import TableListComponent from './table-list.component';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			maxWidth: 500,
			margin: '0 auto',
			height: '100%',
		},
		searchInput: {
			width: 30,
			transition: 'width 1s, border .3s',
			float: 'right',
			backgroundColor: '#6670a7 !important',
			'& .MuiInputBase-root:before': {
				backgroundColor: 'transparent',
				borderColor: 'transparent',
			},
			'& .MuiInputBase-root:after': {
				backgroundColor: 'transparent',
				borderColor: 'transparent',
			},
			'& .MuiSvgIcon-root': {
				color: '#fff',
			},
			'& .MuiInput-input': {
				paddingLeft: 10,
				color: '#fff',
			},
			'&:hover': {
				width: '80%',
				'& .MuiInputBase-root:before': {
					backgroundColor: 'inherit',
					borderColor: 'inherit',
				},
				'& .MuiInputBase-root:after': {
					backgroundColor: 'inherit',
					borderColor: 'inherit',
				},
			},
		},
	})
);
export default function TablesPage() {
	const classes = useStyles();
	const [searchVal, setSearchVal] = useState('');

	const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
		setSearchVal(e.target.value);
	};

	const debouncedResults = useMemo(() => {
		return debouce(handleChange, 300);
	}, []);

	useEffect(() => {
		return () => {
			debouncedResults.cancel();
		};
	});

	return (
		<BasePage>
			<Grid container direction="row" alignItems="flex-start" className={classes.container}>
				<Grid item sm={12} xs={12} height={50}>
					<HeadingComponent text="Tables" />
					<div className="heading-buttons">
						<TextField
							className={classes.searchInput}
							placeholder="Search tables"
							onChange={debouncedResults}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
						/>
						<IconButton component={Link} to="/tables/add" aria-label="add new table" size="large">
							<AddIcon />
						</IconButton>
					</div>
				</Grid>

				<TableListComponent searchVal={searchVal} />
			</Grid>
		</BasePage>
	);
}
