import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import {
  REFRESH_TOKEN_COOKIE_NAME,
  SIGN_UP_STRING,
  TOKEN_COOKIE_NAME,
} from "../core/config";
import { setCookie } from "../core/helpers/storage.helper";
import { useAuth } from "../core/context/auth.context";
import UserService from "../core/service/user.service";
import AuthService from "../core/service/auth.service";
import LoadingButton from "../core/components/loading-button.component";
import { MuiOtpInput } from "mui-one-time-password-input";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import PhoneFormat from "../core/helpers/phone-format.helper";
import WrapperService from "../core/service/wrapper.service";
import { useToast } from "../core/hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      width: "90%",
      "& .MuiFormLabel-root ": {
        textAlign: "center",
        width: "100%",
        transformOrigin: "top center",
      },
      "& .MuiInputBase-input": {
        textAlign: "center",
      },
    },
    resendText: {
      fontWeight: 400,
      fontSize: 13,
      textShadow: "none",
    },
    infoText: {
      fontWeight: 300,
      fontSize: 13,
      marginBottom: 20,
      marginTop: 50,
      textShadow: "none",
      "& b": {
        fontWeight: 500,
      },
    },
    btnVerify: {
      width: "80%",
      marginTop: 10,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 13,
    },
    btnResend: {
      fontSize: 14,
      color: "#24283f",
      textShadow: "none",
      textTransform: "none",
    },
  })
);

export default function VerifyOTPComponent({
  initialValues,
}: {
  initialValues: { phone: string; code: string };
}) {
  const classes = useStyles({});
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { setUser } = useAuth();
  const { showToast } = useToast();

  const userService = new UserService();
  const authService = new AuthService();
  const wrapperService = new WrapperService();

  const [isCodeVerifying, setIsCodeVerifying] = useState<boolean>(false);
  const [isCodeResending, setIsCodeResending] = useState<boolean>(false);

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: initialValues,
  });

  const resendOTP = async () => {
    setIsCodeResending(true);
    try {
      const { phone } = getValues();
      await authService.sendCode({ phone });
      showToast(`OTP code sent to ${PhoneFormat(phone)}!`, "success");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsCodeResending(false);
    }
  };

  const resendOTPCall = async () => {
    setIsCodeResending(true);
    try {
      const { phone } = getValues();
      await authService.sendCodeCall({ phone });
      showToast(`OTP code sent to ${PhoneFormat(phone)}!`, "success");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsCodeResending(false);
    }
  };

  const onSubmit = async ({ phone, code }: { phone: string; code: string }) => {
    setIsCodeVerifying(true);

    const redirectURL = query.get("redirect") || "/tables";

    try {
      const { accessToken, refreshToken, hash } = await authService.verifyCode({
        phone,
        code,
      });

      if (accessToken) {
        const decoded: any = jwt_decode(accessToken as string);

        setCookie(TOKEN_COOKIE_NAME, accessToken);
        setCookie(REFRESH_TOKEN_COOKIE_NAME, refreshToken);

        const profile = await userService.single(decoded.id);

        const redirectURLObj = new URL(redirectURL, window.location.origin);
        redirectURLObj.searchParams.set("userId", decoded.id);
        redirectURLObj.searchParams.set("action", "login");

        wrapperService.notifyLogin(decoded.id);

        setUser(profile);
        navigate(redirectURLObj.href.replace(window.location.origin, ""));
      }

      if (hash && hash === SIGN_UP_STRING) {
        navigate(`/sign-up?p=${phone}&redirect=${redirectURL}`);
      }
    } catch (e: any) {
      showToast(e.message, "error");
      if (
        e.response.data &&
        e.response.data.statusCode === 401 &&
        e.response.data.message === "User not registered!"
      )
        navigate(`/sign-up?p=${phone}&redirect=${redirectURL}`);
    } finally {
      setIsCodeVerifying(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        rules={{ validate: (value) => value.length === 6 }}
        render={({ field, fieldState }) => (
          <Box>
            <MuiOtpInput
              autoFocus
              sx={{ gap: 1 }}
              {...field}
              length={6}
              TextFieldsProps={{
                variant: "filled",
                inputProps: { "data-testId": "otpInput", type: "number" },
              }}
            />
            {fieldState.invalid ? (
              <FormHelperText error>OTP invalid</FormHelperText>
            ) : null}
          </Box>
        )}
        name="code"
      />

      <Typography className={classes.resendText} marginTop={3} marginBottom={1}>
        I didn't receive a code!
        <LoadingButton
          isLoading={isCodeResending}
          type="button"
          onClick={resendOTP}
          text="Resend"
          className={classes.btnResend}
          color="primary"
          variant="text"
        />
        or
        <LoadingButton
          isLoading={isCodeResending}
          type="button"
          onClick={resendOTPCall}
          text="Make a call"
          className={classes.btnResend}
          color="primary"
          variant="text"
        />
      </Typography>
      <LoadingButton
        isLoading={isCodeVerifying}
        text="VERIFY"
        className={classes.btnVerify}
        color="secondary"
      />
    </form>
  );
}
