import { FC, useCallback, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TableService from "../service/table.service";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import DialogContent from "@mui/material/DialogContent";
import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";
import ConfirmDialog from "./confirmation-dialog.component";
import { useToast } from "../hooks/use-toast.hook";
import TablePlayerStatus from "@homegame/common/dist/enum/table-player-status.enum";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#fff",
      fontWeight: 300,
      textAlign: "center",
    },
    btn: {
      cursor: "pointer",
      marginLeft: 15,
    },
  })
);

export default function TablePlayersComponent({
  onClose,
  table,
  onAction,
}: {
  onClose?: () => any;
  onAction?: () => any;
  table: TableInterface;
}) {
  const classes: any = useStyles({});
  const tableService = new TableService();
  const { showToast } = useToast();

  const [isPlayerDeleting, setIsPlayerDeleting] = useState(false);
  const [isInviteSending, setIsInviteSending] = useState(false);

  const [modalState, setModalState] = useState<{
    tablePlayerId: number | null;
    type: "delete" | "invite" | null;
  }>({
    type: null,
    tablePlayerId: null,
  });

  const handleSendInvite = useCallback(async () => {
    if (!modalState.tablePlayerId || !table.id) return;
    setIsInviteSending(true);
    try {
      await tableService.resendInvite(modalState.tablePlayerId, table.id);
      showToast("Invite sent!", "success");
      if (onAction) onAction();
    } catch (error) {
      showToast((error as Error).message, "error");
    } finally {
      await handleCloseModal();
      setIsInviteSending(false);
    }
  }, [table.id, showToast, modalState]);

  const handleDeletePlayer = useCallback(async () => {
    if (!modalState.tablePlayerId) return;
    setIsPlayerDeleting(true);
    try {
      await tableService.deletePlayer(table.id, modalState.tablePlayerId);
      showToast("Player removed!", "warning");
      if (onAction) onAction();
    } catch (error) {
      showToast((error as Error).message, "error");
    } finally {
      await handleCloseModal();
      setIsPlayerDeleting(false);
    }
  }, [table.id, showToast, modalState]);

  const handleCloseModal = useCallback(async () => {
    setModalState({ type: null, tablePlayerId: null });
  }, [modalState]);

  if (!table.players) return;

  const handleClose = () => {
    if (onClose) onClose();
  };

  const showModal = (type: "delete" | "invite", tablePlayerId: number) => {
    setModalState({ type, tablePlayerId });
  };

  const PlayerListItem: FC<{
    player: TablePlayerInterface;
  }> = ({ player }) => {
    const {
      status,
      playerId,
      id: tablePlayerId,
      phone,
      player: playerData,
    } = player;
    const { name } = playerData || {};

    const userIsCashier = table.userId === playerId;

    let playerName = player.player && name ? name : phone;
    if (userIsCashier) playerName += " (cashier)";

    return (
      <ListItem>
        <ListItemText primary={playerName} secondary={status} />
        {!userIsCashier && (
          <>
            {status !== TablePlayerStatus.ACCEPTED && (
              <SendIcon
                className={classes.btn}
                color="info"
                onClick={() => showModal("invite", tablePlayerId!)}
              />
            )}
            <DeleteIcon
              className={classes.btn}
              color="secondary"
              onClick={() => showModal("delete", tablePlayerId!)}
            />
          </>
        )}
      </ListItem>
    );
  };

  return (
    <Dialog
      PaperProps={{ style: { padding: 10, alignItems: "center" } }}
      onClose={handleClose}
      open={true}
    >
      <DialogTitle className={classes.title}>Table players</DialogTitle>

      <DialogContent style={{ padding: 0, width: "100%" }}>
        <List>
          {table.players.map((p, i) => (
            <PlayerListItem player={p} key={i} />
          ))}
        </List>
      </DialogContent>
      <ConfirmDialog
        text="Are you sure you want to remove player?"
        isLoading={isPlayerDeleting}
        actionText="Remove player"
        open={modalState.type === "delete"}
        onClose={handleCloseModal}
        action={handleDeletePlayer}
      />
      <ConfirmDialog
        text="Are you sure you want to re-send invite?"
        isLoading={isInviteSending}
        actionText="Re-send invite"
        open={modalState.type === "invite"}
        onClose={handleCloseModal}
        action={handleSendInvite}
      />
    </Dialog>
  );
}
