import formatCurrency from "../../../core/helpers/format-currency.helper";
import CardComponent from "./card.component";
import { useGame } from "../../game.context";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import BalanceIcon from "@mui/icons-material/Balance";

interface CardWinLostProps {
  isPlayerView?: boolean;
  amountWonLost: number;
}

/**
 * A React component that displays a card showing the amount won or lost.
 *
 * @param {number} amountWonLost - The amount won or lost in the game.
 * @param {boolean} [isPlayerView=false] - Whether the card is being viewed from the player's perspective.
 * @return {JSX.Element} A CardComponent with the amount won or lost and a corresponding icon.
 */
export default function CardWinLostComponent({
  amountWonLost,
  isPlayerView = false,
}: CardWinLostProps): JSX.Element {
  const { game } = useGame();

  if (!game) return <></>;

  let icon = <BalanceIcon />;
  if (amountWonLost > 0) icon = <EmojiEventsIcon />;
  if (amountWonLost < 0) icon = <SentimentVeryDissatisfiedIcon />;

  const appealText = isPlayerView ? "You" : "Player";

  let text = <span>{`${isPlayerView ? "You are" : "Player is"} even`}</span>;

  if (amountWonLost > 0) text = <span>{`${appealText} won`}</span>;
  if (amountWonLost < 0) text = <span>{`${appealText} lost`}</span>;

  return (
    <CardComponent
      icon={icon}
      primary={formatCurrency(Math.abs(amountWonLost), game.currency)}
      secondary={text}
    />
  );
}
