import TransactionInterface from "@homegame/common/dist/interface/transaction.interface";
import getPlayerTransactions from "./get-player-transactions";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";

/**
 * Retrieves the last transaction of a specific player from a game.
 *
 * @param {number} playerId - The ID of the player whose last transaction is to be retrieved.
 * @param transactions
 * @returns {TransactionInterface | undefined} The last transaction of the specified player in the provided game. If the game is undefined or the player has no transactions, the function will return undefined.
 */
export default function getPlayerLastTransaction(
  playerId: number | undefined,
  transactions: TransactionInterface[] | undefined
): TransactionInterface | undefined {
  // Return undefined if the game or player is not defined
  if (!transactions || !playerId) return undefined;

  const userTransactions = getPlayerTransactions(playerId, transactions);

  return userTransactions
    ?.filter((transaction) => transaction.status === TransactionStatus.APPROVED)
    .sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    })[0];
}
