import { Dayjs } from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles(() =>
	createStyles({
		iconContainer: {
			padding: 5,
		},
		icon: {
			padding: 10,
			color: '#fff',
			'&:hover': {
				background: 'none',
			},
		},
	})
);

interface HeaderProps {
	date: Dayjs;
	setDate: (date: Dayjs) => void;
	nextDisabled: boolean;
	prevDisabled: boolean;
	onClickNext: () => void;
	onClickPrevious: () => void;
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const generateYears = (relativeTo: Dayjs, count: number) => {
	const half = Math.floor(count / 2);
	return Array(count)
		.fill(0)
		.map((_y, i) => relativeTo.year() - half + i); // TODO: make part of the state
};

const Header: React.FunctionComponent<HeaderProps> = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious }: HeaderProps) => {
	const classes = useStyles();

	const handleMonthChange = (event: SelectChangeEvent) => {
		setDate(date.month(parseInt(event.target.value)));
	};

	const handleYearChange = (event: SelectChangeEvent) => {
		setDate(date.year(parseInt(event.target.value)));
	};

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid item className={classes.iconContainer}>
				<IconButton className={classes.icon} disabled={prevDisabled} onClick={onClickPrevious}>
					<ChevronLeft color={prevDisabled ? 'disabled' : 'secondary'} />
				</IconButton>
			</Grid>
			<Grid item>
				<Select
					variant="standard"
					value={date.month().toString()}
					onChange={handleMonthChange}
					style={{ color: '#fff' }}
					MenuProps={{ disablePortal: true }}
				>
					{MONTHS.map((month, idx) => (
						<MenuItem key={month} value={idx}>
							{month}
						</MenuItem>
					))}
				</Select>
			</Grid>

			<Grid item>
				<Select
					variant="standard"
					value={date.year().toString()}
					onChange={handleYearChange}
					style={{ color: '#fff' }}
					MenuProps={{ disablePortal: true }}
				>
					{generateYears(date, 30).map((year) => (
						<MenuItem key={year} value={year}>
							{year}
						</MenuItem>
					))}
				</Select>
			</Grid>
			<Grid item className={classes.iconContainer}>
				<IconButton className={classes.icon} disabled={nextDisabled} onClick={onClickNext}>
					<ChevronRight color={nextDisabled ? 'disabled' : 'secondary'} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default Header;
