import { useController, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Theme } from '@mui/material';

interface IProps {
	name: string;
	disablePast?: boolean;
	disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popper: {
			'& .react-datepicker__current-month': { textShadow: 'none', color: 'white' },
			'& .react-datepicker__day-name': { textShadow: 'none', color: 'white' },
			'& .react-datepicker__header ': { backgroundColor: theme.palette.primary.main, color: 'white' },
			'& .react-datepicker__triangle::before ': { borderBottomColor: `${theme.palette.primary.main} !important` },
			'& .react-datepicker__triangle::after ': { borderBottomColor: `${theme.palette.primary.main} !important` },
			'& .react-datepicker ': {
				borderColor: '#eee',
				border: 'none',
				boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
			},
			'& .react-datepicker__day--selected': {
				backgroundColor: theme.palette.secondary.main,
				color: 'white',
				fontWeight: 'bold',
			},
			'& .react-datepicker__day--keyboard-selected': {
				backgroundColor: theme.palette.secondary.main,
				color: 'white',
				fontWeight: 'bold',
			},
		},
		datePicker: {
			'& .MuiInputBase-root': { backgroundColor: 'transparent' },
			'& .MuiInputBase-input': { color: 'white', textShadow: '1px 1px black' },
			'& .MuiInputAdornment-root .MuiSvgIcon-root': { fontSize: 17, marginTop: -3, color: 'white', textShadow: '1px 1px black' },
		},
		datePickerDayRoot: {
			color: 'black',
			textShadow: 'none',
			'&.react-datepicker__day--disabled': { color: 'rgba(0, 0, 0, .3) !important' },
		},
	})
);

export const DateInput = ({ name, disablePast = false, disabled = false }: IProps) => {
	const classes: any = useStyles({});

	const { control } = useFormContext();
	const { field } = useController({ control, name, defaultValue: null });

	return (
		<DatePicker
			minDate={disablePast ? new Date() : undefined}
			selected={field.value}
			className={classes.datePicker}
			onChange={field.onChange}
			disabled={disabled}
			withPortal
			forceShowMonthNavigation
			popperPlacement="top-end"
			name={field.name}
			popperClassName={classes.popper}
			customInput={
				<TextField
					variant="standard"
					fullWidth
					placeholder="Select game date"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<CalendarMonthIcon color="secondary" fontSize="small" />
							</InputAdornment>
						),
					}}
				/>
			}
			dayClassName={() => classes.datePickerDayRoot}
		/>
	);
};
