import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ButtonGroup from "@mui/material/ButtonGroup";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import IconButton from "@mui/material/IconButton";
import {
  DateRange,
  DateRangePicker,
} from "../core/components/date-range-picker";
import BasePage from "../core/base.page";
import HeadingComponent from "../core/components/heading.component";
import { defaultRanges } from "../core/components/date-range-picker/defaults";
import { useAuth } from "../core/context/auth.context";
import StatsService from "../core/service/stats.service";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
ChartJS.defaults.color = "#fff";
ChartJS.defaults.borderColor = "#8c8b8b";
// ChartJS.defaults.borderColor = "#fff";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      textAlign: "center",
      paddingBottom: 120,
    },
    btn: {
      border: "1px solid #fff",
      color: "#fff",
      "& .MuiButtonBase-root": { color: "#fff" },
      "& .MuiButton-root": {
        color: "#fff",
        fontSize: 13,
        textTransform: "none",
        "& .MuiSvgIcon-root": {
          marginRight: 10,
          marginLeft: 5,
        },
      },
    },
    chartCanvas: {},
    chartwrapper: {
      "&:after": {
        backgroundImage: "url(/cards.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "70%",
        content: '""',
        display: "block",
        position: "absolute",
        width: "100%",
        height: "100%",
        filter: "opacity(0.1)",
        top: 0,
        left: 0,
        zIndex: -1,
      },
    },
    noItemsText: {
      textAlign: "center",
      fontSize: 14,
      display: "block",
      color: "#fff",
      fontWeight: 400,
      position: "absolute",
      top: "50%",
      width: "100%",
      textShadow: "none",
    },
  })
);

export default function StatsPage() {
  const classes = useStyles({});

  const statsService = new StatsService();

  const { user } = useAuth();

  const [transactionsData, setTransactionsData] = useState<ChartData<"bar">>({
    labels: [],
    datasets: [],
  });

  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>(defaultRanges[2]);

  const toggle = () => setOpen(!open);

  const getDatesString = () => {
    const { label, startDate, endDate } = dateRange;
    if (label) return label;
    if (!startDate || !endDate) return "Select date";
    return `${startDate.format("DD MMM")} - ${endDate.format("DD MMM")}`;
  };

  const prevRange = () => {
    const { startDate, endDate } = dateRange;
    if (!startDate || !endDate) return;
    const daysDiff = startDate!.diff(endDate, "days");
    setDateRange({
      startDate: startDate.subtract(daysDiff, "days"),
      endDate: endDate.subtract(daysDiff, "days"),
    });
  };

  const nextRange = () => {
    const { startDate, endDate } = dateRange;
    if (!startDate || !endDate) return;
    const daysDiff = startDate!.diff(endDate, "days");
    setDateRange({
      startDate: startDate.add(daysDiff, "days"),
      endDate: endDate.add(daysDiff, "days"),
    });
  };

  const changeRange = (range: DateRange) => {
    setDateRange(range);
    toggle();
  };

  const clearDate = () => {
    setDateRange(defaultRanges[2]);
  };

  const { data, refetch } = useQuery({
    queryKey: [`singleUserData`, { userId: user!.id }],
    queryFn: () => {
      if (!dateRange.startDate || !dateRange.endDate) return;
      const startDate = dateRange.startDate.startOf("day");
      const endDate = dateRange.endDate.endOf("day");
      return statsService.userStatByWinLoseMoney(user!.id!, {
        dateFrom: startDate.format(),
        dateTo: endDate.format(),
      });
    },
    initialData: [],
  });

  useEffect(() => {
    if (!data || !Array.isArray(data)) return;
    setTransactionsData({
      labels: data.map((r) => dayjs(r.createdAt).format("D MMM")),
      datasets: [
        {
          label: "Won",
          data: data.filter((t) => t.result > 0).map((t) => t.result),
          backgroundColor: "#66bb6a",
        },
        {
          label: "Loose",
          data: data.filter((t) => t.result < 0).map((t) => t.result),
          backgroundColor: "#ef5350",
        },
      ],
    });
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dateRange]);

  return (
    <BasePage>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        className={classes.container}
      >
        <Grid item xs={12}>
          <HeadingComponent text="Stats" />
        </Grid>
        <Grid item sm={12} xs={12}>
          <ButtonGroup variant="outlined" aria-label="text button group">
            <Button onClick={prevRange} className={classes.btn}>
              <ChevronLeftIcon sx={{ fontSize: 17 }} />
            </Button>
            <div className={classes.btn}>
              <Button variant="text" onClick={() => setOpen(true)}>
                {/*<p>*/}
                <CalendarMonthIcon sx={{ fontSize: 17 }} />
                {getDatesString()}
                {/*</p>*/}
              </Button>
              <IconButton
                onClick={clearDate}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ClearIcon
                  style={{ position: "relative", zIndex: 9 }}
                  sx={{ fontSize: 17 }}
                />
              </IconButton>
            </div>
            <Button onClick={nextRange} className={classes.btn}>
              <ChevronRightIcon sx={{ fontSize: 17 }} />
            </Button>
          </ButtonGroup>

          <br />
          <br />
        </Grid>

        <Grid
          item
          xs={12}
          style={{ position: "relative" }}
          className={classes.chartwrapper}
        >
          <Bar data={transactionsData} options={{ color: "#fff" }} />
          {transactionsData?.labels?.length === 0 && (
            <Typography className={classes.noItemsText}>
              No finished games for selected dates
            </Typography>
          )}
        </Grid>
      </Grid>
      <DateRangePicker open={open} toggle={toggle} onChange={changeRange} />
    </BasePage>
  );
}
