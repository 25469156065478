import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DateRange, NavigationAction, DefinedRange } from '../types';
import { getValidatedMonths, parseOptionalDate } from '../utils';
import { defaultRanges } from '../defaults';
import Menu from './Menu';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
	FIRST_MONTH: Symbol('firstMonth'),
	SECOND_MONTH: Symbol('secondMonth'),
};

interface DateRangePickerProps {
	open: boolean;
	initialDateRange?: DateRange;
	definedRanges?: DefinedRange[];
	minDate?: Dayjs | string;
	maxDate?: Dayjs | string;
	onChange: (dateRange: DateRange) => void;
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = (props: DateRangePickerProps) => {
	const today = dayjs();

	const { open, onChange, initialDateRange, minDate, maxDate, definedRanges = defaultRanges } = props;

	const minDateValid = parseOptionalDate(minDate, today.add(-10, 'years'));
	const maxDateValid = parseOptionalDate(maxDate, today.add(10, 'years'));
	const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

	const [dateRange, setDateRange] = useState<DateRange>({ ...initialDateRange });
	const [hoverDay, setHoverDay] = useState<Dayjs>();
	const [firstMonth, setFirstMonth] = useState<Dayjs | undefined>(intialFirstMonth || today);
	const [secondMonth, setSecondMonth] = useState<Dayjs | undefined>(initialSecondMonth || firstMonth?.add(1, 'month'));

	const { startDate, endDate } = dateRange;

	// handlers
	const setFirstMonthValidated = (date: Dayjs) => {
		if (date.isBefore(secondMonth)) {
			setFirstMonth(date);
		}
	};

	const setSecondMonthValidated = (date: Dayjs) => {
		if (date.isAfter(firstMonth)) {
			setSecondMonth(date);
		}
	};

	const setDateRangeValidated = (range: DateRange) => {
		let { startDate: newStart, endDate: newEnd } = range;

		if (!!newStart && !!newEnd) {
			range.startDate = newStart = dayjs.max(newStart, minDateValid) || undefined;
			range.endDate = newEnd = dayjs.min(newEnd, maxDateValid) || undefined;

			setDateRange(range);
			onChange(range);

			setFirstMonth(newStart);
			setSecondMonth(newStart?.isSame(newEnd, 'month') ? newStart.add(1, 'month') : newEnd);
		} else {
			const emptyRange = {};

			setDateRange(emptyRange);
			onChange(emptyRange);

			setFirstMonth(today);
			setSecondMonth(firstMonth?.add(1, 'month'));
		}
	};

	const onDayClick = (day: Dayjs) => {
		if (startDate && !endDate && !day.isBefore(startDate)) {
			const newRange = { startDate, endDate: day };
			onChange(newRange);
			setDateRange(newRange);
		} else {
			setDateRange({ startDate: day, endDate: undefined });
		}
		setHoverDay(day);
	};

	const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
		if (marker === MARKERS.FIRST_MONTH) {
			const firstNew = firstMonth?.add(action, 'month');
			if (firstNew?.isBefore(secondMonth)) setFirstMonth(firstNew);
		} else {
			const secondNew = secondMonth?.add(action, 'month');
			if (firstMonth?.isBefore(secondNew)) setSecondMonth(secondNew);
		}
	};

	const onDayHover = (date: Dayjs) => {
		if (startDate && !endDate) {
			if (!hoverDay || !date.isSame(hoverDay, 'day')) {
				setHoverDay(date);
			}
		}
	};

	// helpers
	const inHoverRange = (day: Dayjs) =>
		(startDate && !endDate && hoverDay && hoverDay.isAfter(startDate) && day.isBetween(startDate, hoverDay)) as boolean;

	const helpers = {
		inHoverRange,
	};

	const handlers = {
		onDayClick,
		onDayHover,
		onMonthNavigate,
	};

	return open ? (
		<Menu
			dateRange={dateRange}
			minDate={minDateValid}
			maxDate={maxDateValid}
			ranges={definedRanges}
			firstMonth={firstMonth}
			secondMonth={secondMonth}
			setFirstMonth={setFirstMonthValidated}
			setSecondMonth={setSecondMonthValidated}
			setDateRange={setDateRangeValidated}
			helpers={helpers}
			handlers={handlers}
		/>
	) : null;
};

export default DateRangePicker;
