import SystemSnackbarComponent from "../core/components/system-snackbar.component";
import SystemLoaderComponent from "../core/components/system-loader.component";
import ContentComponent from "../core/components/content.component";
import BottomNavigationComponent from "./components/bottom-navigation.component";
import { PullToRefreshProvider } from "./context/pull-to-refresh.context";
import { SwipeNavProvider } from "./context/swipe-nav.context";

export default function BasePage({ children }: any) {
  return (
    <>
      <SwipeNavProvider>
        <BottomNavigationComponent />
        <ContentComponent>
          <PullToRefreshProvider>{children}</PullToRefreshProvider>
        </ContentComponent>
        <SystemSnackbarComponent />
        <SystemLoaderComponent />
      </SwipeNavProvider>
    </>
  );
}
