import { useState } from "react";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import ConfirmDialog from "../../core/components/confirmation-dialog.component";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import GameService from "../../core/service/game.service";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import getPlayerLastTransaction from "../../core/hooks/useGameInfo/get-player-last-transaction";
import { useToast } from "../../core/hooks/use-toast.hook";
import { useGame } from "../game.context";

interface PlayerDeleteBtnProps {
  user: UserInterface | undefined;
  onPlayerDelete?: () => void;
}
export default function PlayerDeleteBtn({
  user,
  onPlayerDelete,
}: PlayerDeleteBtnProps) {
  const { showToast } = useToast();
  const gameService = new GameService();
  const navigate = useNavigate();

  const { isCashier, isOngoing, isCanceled, isFinished, game } = useGame();

  const [isPlayerDeleting, setIsPlayerDeleting] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  if (!user || !isCashier || isCanceled || isFinished || !game) return <></>;

  const lastPlayerTransaction = getPlayerLastTransaction(
    user.id!,
    game.transactions
  );

  const playerBuyIns =
    game?.transactions?.filter(
      (t) => t.fromUserId === user.id && t.type === TransactionType.BUY_IN
    ) || [];

  if (
    (lastPlayerTransaction &&
      lastPlayerTransaction?.type !== TransactionType.CASH_OUT) ||
    playerBuyIns.length !== 0
  )
    return <></>;

  const closeDelConfirmation = () => setDeleteConfirmation(false);
  const openDelConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const handlePlayerDelete = async () => {
    const playersCashOut = game.transactions?.filter(
      (transaction) =>
        transaction.type === TransactionType.CASH_OUT &&
        transaction.toUserId === user?.id
    );

    if (isOngoing && playersCashOut?.length === 0)
      showToast("You can't leave the game without cashing out!", "error");

    setIsPlayerDeleting(true);
    try {
      await gameService.deletePlayer(game.id, user?.id);
      showToast(isCashier ? "Player deleted" : "You left the game!", "warning");
      if (onPlayerDelete) onPlayerDelete();
      if (!isCashier) navigate("/");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsPlayerDeleting(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: "#4d589c" }}
        onClick={openDelConfirmation}
        startIcon={<ExitToAppIcon />}
      >
        {isCashier ? "Remove" : "Leave game"}
      </Button>

      <ConfirmDialog
        text={
          isCashier
            ? "Are you sure you want to remove player?"
            : "Are you sure you want to leave the game?"
        }
        isLoading={isPlayerDeleting}
        actionText={isCashier ? "Delete player" : "Leave game"}
        open={deleteConfirmation}
        onClose={closeDelConfirmation}
        action={handlePlayerDelete}
      />
    </>
  );
}
