import { GeolocationData } from './geolocate.helper';
async function ipLookUpHelper(): Promise<GeolocationData> {
	try {
		return await fetch('https://ipapi.co/json/')
			.then((r) => r.json())
			.then((result) => {
				const { country, country_name: countryName, city, state, latitude, longitude, timezone, utc_offset: utcOffset } = result;

				if (result.error) throw new Error(result.error);

				return {
					countryCode: country,
					country: countryName,
					city,
					state,
					latitude,
					longitude,
					timezone,
					utc_offset: parseFloat(utcOffset),
				};
			})
			.catch((_) => {
				return {
					countryCode: 'United States',
					country: 'US',
					city: 'New York',
					state: 'NY',
					latitude: 40.73061,
					longitude: -73.935242,
					timezone: 'America/New_York',
					utc_offset: -4,
				};
			});
	} catch (e) {
		throw new Error((e as Error).message);
	}
}

export default ipLookUpHelper;
