import UserInterface from "@homegame/common/src/interface/user.interface";

import {
  deleteLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./storage.helper";
import { axios } from "../api";

export const getUserDataHelper = (): UserInterface | undefined => {
  try {
    return getLocalStorage("user");
  } catch (e) {
    return undefined;
  }
};
export const setUserDataHelper = (userData: UserInterface | undefined) =>
  userData && setLocalStorage("user", userData);
export const delUserDataHelper = () => deleteLocalStorage("user");

export const resetAuthHelper = async () =>
  axios.post("auth/log-out").then(() => {
    delUserDataHelper();
    deleteLocalStorage("u");
  });
