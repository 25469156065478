import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TableInterface from '@homegame/common/src/interface/table.interface';
import { useLoader } from '../core/context/loader.context';
import TableService from '../core/service/table.service';
import BasePage from '../core/base.page';
import TableForm from './table.form';

export default function EditTablePage() {
	const tableService = new TableService();
	const navigate = useNavigate();
	const [data, setData] = useState<TableInterface | undefined>();
	const { tableId } = useParams();
	const { showLoader, hideLoader } = useLoader();

	const loadTableData = useCallback(async () => {
		try {
			showLoader();
			const tableData = await tableService.single(tableId);
			setData(tableData);
		} catch (e) {
			console.error(e);
		} finally {
			hideLoader();
		}
	}, [tableId]);

	useEffect(() => {
		if (tableId) loadTableData().then();
	}, [tableId]);

	const onSave = async (table: TableInterface) => {
		if (tableId) loadTableData().then();
		else navigate(`/tables/${table.id}/view`);
	};

	return (
		<BasePage>
			{tableId && data && <TableForm initialValues={data} onSave={onSave} />}
			{!tableId && <TableForm onSave={onSave} />}
		</BasePage>
	);
}
