import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useAuth } from "../core/context/auth.context";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import Stack from "@mui/material/Stack";
import ConfirmDialog from "../core/components/confirmation-dialog.component";
import CreateGameDialog from "./CreateGame/create-game.component";
import GameService from "../core/service/game.service";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import isAllCashOutsDone from "../core/hooks/useGameInfo/is-all-cashouts-done";
import GameDuration from "./game-duration";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Divider from "@mui/material/Divider";
import { useToast } from "../core/hooks/use-toast.hook";
import { useGame } from "./game.context";
import DoneIcon from "@mui/icons-material/Done";
import formatCurrency from "../core/helpers/format-currency.helper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CardHorizontalComponent from "./components/cards/card-horizontal.component";
import CardComponent from "./components/cards/card.component";

const useStyles = makeStyles(() =>
  createStyles({
    btnsWrap: {
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
      flexWrap: "wrap",
      flexDirection: "row",
      "& button": {
        margin: "16px 5px !important",
        width: "45%",
        "@media (orientation: landscape)": { width: "auto" },
      },
    },
    horizontalCardTitle: {
      textShadow: "none",
      textAlign: "center",
      fontSize: 26,
      fontWeight: 300,
      color: "#2c345c",
      padding: "0 10px",
    },
    horizontalCardSubtitle: {
      fontSize: 13,
      color: "#2c345c",
      textShadow: "none",
      textAlign: "center",
      lineHeight: "10px",
    },
  })
);

export default function GameInfoCashier() {
  const classes = useStyles();
  const gameService = new GameService();
  const { showToast } = useToast();
  const { user } = useAuth();
  const {
    game,
    setPlayersWithErrors,
    isNew,
    isOngoing,
    isFinished,
    reloadGame,
  } = useGame();

  const {
    currentChipsVal,
    totalBuyInChips,
    totalCashOutChips,
    currentMoneyVal,
    bestBuyInChips,
    bestCashOutChips,
    playerWithBiggestWonMoney,
    totalCashOutMoney,
    totalBuyInMoney,
    playerWithBiggestLossMoney,
    gameRate,
    totalGameDebt,
    totalPayedDebts,
  } = useGameInfo(game);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    type: "start" | "cancel" | "finish";
    isLoading: boolean;
  }>({
    open: false,
    type: "start",
    isLoading: false,
  });
  const [editGameOpened, setEditGameOpened] = useState<boolean>(false);

  if (!game) return <></>;

  const handleDialogClose = () =>
    setDialogState({ open: false, type: "start", isLoading: false });

  const handleDialogOpen = (type: "start" | "cancel" | "finish") => {
    setDialogState({ open: true, type, isLoading: false });
  };

  const handleOnFinishGameClick = () => {
    if (!game) return;

    if (!isAllCashOutsDone(game)) {
      showToast(
        "All players must do cash out before finishing game.",
        "warning"
      );
      return;
    }

    if (currentChipsVal > 0) {
      showToast(`${currentChipsVal} chips are still not returned`, "warning");
      return;
    }

    handleDialogOpen("finish");
  };

  const toggleGame = () => setEditGameOpened(!editGameOpened);

  const onGameClose = async () => {
    setEditGameOpened(false);
    await reloadGame();
  };

  const doDialogAction = async () => {
    setDialogState({ ...dialogState, isLoading: true });
    try {
      if (dialogState.type === "start") {
        await gameService.startGame(game.id!);
        showToast("Game started!", "info");
      } else if (dialogState.type === "cancel") {
        await gameService.cancelGame(game.id!);
        showToast("Game canceled!", "info");
      } else if (dialogState.type === "finish") {
        await gameService.finishGame(game.id!);
        showToast("Game finished!", "info");
      }
      await reloadGame();
    } catch (e) {
      const { message } = e as Error;
      if (dialogState.type === "start") {
        const playerIds = game?.players?.map((p) => Number(p.playerId)) || [];
        const buyInsIds =
          game?.transactions
            ?.filter((t) => t.type === TransactionType.BUY_IN)
            .map((t) => t.toUserId) || [];
        const playersWithoutBuyIns = playerIds.filter(
          (pId) => !buyInsIds.includes(Number(pId))
        );

        if (playersWithoutBuyIns.length)
          setPlayersWithErrors(playersWithoutBuyIns);
      }
      showToast(message, "error");
    } finally {
      setDialogState({ ...dialogState, isLoading: false, open: false });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!isNew && (
          <>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <CardComponent
                icon={<AccessTimeIcon />}
                primary={<GameDuration game={game} textVariant="body1" />}
                secondary="Game duration"
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </>
        )}

        {isFinished && playerWithBiggestWonMoney && (
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              style={{ color: "red", textShadow: "none" }}
            >
              Total debt in game:{" "}
              <b>{formatCurrency(totalGameDebt, game.currency)}</b>{" "}
              <span style={{ color: "green" }}>
                ({formatCurrency(totalPayedDebts, game.currency)} returned)
              </span>
            </Typography>
          </Grid>
        )}

        <Grid item xs={6}>
          <CardComponent
            icon={
              <img
                style={{ width: 30, height: 30, marginBottom: -4 }}
                src="/ic_stat_onesignal_default.png"
                alt="chip icon"
              />
            }
            primary={currentChipsVal}
            secondary={
              <>
                Chips in game
                <br />
                <small>
                  ({formatCurrency(currentChipsVal * gameRate, game.currency)})
                </small>
              </>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <CardComponent
            icon={
              <span>
                {formatCurrency(currentMoneyVal, game.currency, false)}
              </span>
            }
            primary={currentMoneyVal.toFixed(0)}
            secondary={
              <>
                {isNew && (
                  <>
                    <span style={{ color: "#63bc65" }}>Received</span> money
                  </>
                )}
                {!isNew && (
                  <>
                    <span style={{ color: "#63bc65" }}>Real</span> money in game
                  </>
                )}
                <br /> <span style={{ color: "transparent" }}>___</span>
              </>
            }
          />
        </Grid>

        {!isNew && (
          <Grid item xs={6}>
            <CardComponent
              icon={
                <img
                  style={{ width: 30, height: 30, marginBottom: -4 }}
                  src="/ic_stat_onesignal_default.png"
                  alt="chip icon"
                />
              }
              primary={totalBuyInChips}
              secondary={
                <>
                  Total Buyin chips
                  <br />
                  <small>
                    ({formatCurrency(totalBuyInChips * gameRate, game.currency)}
                    )
                  </small>
                </>
              }
            />
          </Grid>
        )}

        {!isNew && (
          <Grid item xs={6}>
            <CardComponent
              icon={
                <span>
                  {formatCurrency(totalBuyInMoney, game.currency, false)}
                </span>
              }
              primary={totalBuyInMoney}
              secondary={
                <>
                  Total Buyin <span style={{ color: "#63bc65" }}>real</span>{" "}
                  money
                  <span style={{ color: "transparent" }}>___</span>
                </>
              }
            />
          </Grid>
        )}

        {!isNew && (
          <Grid item xs={6}>
            <CardComponent
              icon={
                <img
                  style={{ width: 30, height: 30, marginBottom: -4 }}
                  src="/ic_stat_onesignal_default.png"
                  alt="chip icon"
                />
              }
              primary={totalCashOutChips}
              secondary={
                <>
                  Total Cashout chips
                  <br />
                  <small>
                    (
                    {formatCurrency(
                      totalCashOutChips * gameRate,
                      game.currency
                    )}
                    )
                  </small>
                </>
              }
            />
          </Grid>
        )}

        {!isNew && (
          <Grid item xs={6}>
            <CardComponent
              icon={
                <span>
                  {formatCurrency(totalCashOutMoney, game.currency, false)}
                </span>
              }
              primary={totalCashOutMoney}
              secondary={
                <>
                  Total Cashout <span style={{ color: "#63bc65" }}>real</span>{" "}
                  money
                </>
              }
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {isFinished && playerWithBiggestWonMoney && (
          <Grid item xs={12}>
            <CardHorizontalComponent
              icon={<EmojiEventsIcon />}
              secondary={
                <>
                  Biggest win <b>{playerWithBiggestWonMoney.name}</b>
                </>
              }
              primary={formatCurrency(
                Math.abs(
                  playerWithBiggestWonMoney.totalBuyIn.chips -
                    playerWithBiggestWonMoney.totalCashout.chips
                ),
                game.currency
              )}
            />
          </Grid>
        )}
        {isFinished && playerWithBiggestLossMoney && (
          <Grid item xs={12}>
            <CardHorizontalComponent
              icon={<SentimentVeryDissatisfiedIcon />}
              secondary={
                <>
                  Biggest loss <b>{playerWithBiggestLossMoney.name}</b>
                </>
              }
              primary={formatCurrency(
                playerWithBiggestLossMoney.totalBuyIn.chips -
                  playerWithBiggestLossMoney.totalCashout.chips,
                game.currency
              )}
            />
          </Grid>
        )}

        {!isNew && bestBuyInChips && (
          <Grid item xs={12}>
            <CardHorizontalComponent
              icon={<FileDownloadIcon />}
              secondary={
                <>
                  Biggest total buyin by <b>{bestBuyInChips.name}</b>
                </>
              }
              primary={
                <>
                  <Typography className={classes.horizontalCardTitle}>
                    {bestBuyInChips.total}
                  </Typography>
                  <Typography className={classes.horizontalCardSubtitle}>
                    chips{" "}
                    <small style={{ color: "rgba(245,0,87,.6)" }}>
                      (
                      {formatCurrency(
                        bestBuyInChips.total * gameRate,
                        game.currency
                      )}
                      )
                    </small>
                  </Typography>
                </>
              }
            />
          </Grid>
        )}

        {!isNew && bestCashOutChips && (
          <Grid item xs={12}>
            <CardHorizontalComponent
              icon={<UploadIcon />}
              secondary={
                <>
                  Biggest total cash out by <b>{bestCashOutChips.name}</b>
                </>
              }
              primary={
                <>
                  <Typography className={classes.horizontalCardTitle}>
                    {bestCashOutChips.total}
                  </Typography>
                  <Typography className={classes.horizontalCardSubtitle}>
                    chips{" "}
                    <small style={{ color: "rgba(245,0,87,.6)" }}>
                      (
                      {formatCurrency(
                        bestCashOutChips.total * gameRate,
                        game.currency
                      )}
                      )
                    </small>
                  </Typography>
                </>
              }
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12} alignSelf="center">
          {(isNew || isOngoing) && (
            <Stack spacing={2} className={classes.btnsWrap}>
              {isOngoing && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOnFinishGameClick}
                  startIcon={<DoneIcon />}
                >
                  Finish game
                </Button>
              )}

              {isNew && (
                <>
                  <Grid item xs={12} p={1}>
                    <Divider sx={{ mt: 0, mb: 0 }} />
                  </Grid>
                  <Button
                    onClick={() => handleDialogOpen("start")}
                    color="success"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                  >
                    Start game
                  </Button>
                  <Button
                    onClick={toggleGame}
                    variant="contained"
                    color="warning"
                    startIcon={<EditIcon />}
                  >
                    Edit game
                  </Button>
                  <Button
                    onClick={() => handleDialogOpen("cancel")}
                    color="error"
                    variant="contained"
                    startIcon={<DoDisturbIcon />}
                  >
                    Cancel game
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>

      <ConfirmDialog
        text={`Are you sure you want to ${dialogState.type} game?`}
        isLoading={dialogState.isLoading}
        actionText={`${dialogState.type} game`}
        open={dialogState.open}
        onClose={handleDialogClose}
        action={doDialogAction}
      />

      {editGameOpened && (
        <CreateGameDialog
          initialValues={game}
          table={game.table}
          userId={Number(user?.id)}
          onClose={onGameClose}
        />
      )}
    </>
  );
}
