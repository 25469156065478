import BaseService from "./base.service";
import UserDebtInterface from "@homegame/common/dist/interface/user-debt.interface";
import UserDebtFilterInterface from "@homegame/common/dist/interface/filters/user-debt-filter.interface";

export default class UserDebtsService extends BaseService {
  baseEndpoint = "user-debts";

  async list(
    params?: UserDebtFilterInterface
  ): Promise<[UserDebtInterface[], number]> {
    return this.baseList(params);
  }

  async listByGame(
    params?: UserDebtFilterInterface
  ): Promise<[UserDebtInterface[], number]> {
    return this.request<[any[], number]>({
      method: "get",
      url: `${this.baseEndpoint}/find-by-game`,
      params,
      transformResponse: [this.baseTransformResponse],
    });
  }

  async payTableDebt(params?: {
    fromUserId: number;
    tableId: number;
    toUserId: number;
    amount: number;
  }): Promise<[UserDebtInterface[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/pay-table-debt`,
      data: params,
    });
  }

  async userDebts(params?: {
    fromUserId: number;
    toUserId: number;
    tableId: number;
  }): Promise<UserDebtInterface[]> {
    return this.request({
      method: "get",
      url: `${this.baseEndpoint}/history/?fromUserId=${params?.fromUserId}&toUserId=${params?.toUserId}&tableId=${params?.tableId}`,
      data: params,
      transformResponse: [this.baseTransformResponse],
    });
  }

  async approveTableDebt(params?: {
    fromUserId: number;
    tableId: number;
    toUserId: number;
    amount: number;
  }): Promise<[UserDebtInterface[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/approve-table-debt`,
      data: params,
    });
  }

  async declineTableDebt(params?: {
    fromUserId: number;
    tableId: number;
    toUserId: number;
    amount: number;
  }): Promise<[UserDebtInterface[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/decline-table-debt`,
      data: params,
    });
  }
}
