import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "./core/app";
import { AuthContextProvider } from "./core/context/auth.context";
import HGTheme from "./core/theme";
import { SocketProvider } from "./core/context/socket.context";
import registerServiceWorker from "./serviceWorker";
import { toast } from "react-toastify";
import ErrorBoundary from "./core/components/error-boundary.component";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <HGTheme>
      <CookiesProvider>
        <AuthContextProvider>
          <SocketProvider>
            <Router>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </Router>
          </SocketProvider>
        </AuthContextProvider>
      </CookiesProvider>
    </HGTheme>
  </React.StrictMode>
);

reportWebVitals();
registerServiceWorker(toast);
