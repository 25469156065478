import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import { DateRange, DefinedRange, Setter, NavigationAction } from '../types';
import { MARKERS } from './DateRangePicker';
import { Theme } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			padding: '20px 70px',
		},
		headerItem: {
			flex: 1,
			textAlign: 'center',
			'& .MuiSvgIcon-root ': {
				color: '#fff',
			},
		},
		divider: {
			borderLeft: `1px solid ${theme.palette.action.hover}`,
			marginBottom: 20,
		},
	})
);

interface MenuProps {
	dateRange: DateRange;
	ranges: DefinedRange[];
	minDate: Dayjs;
	maxDate: Dayjs;
	firstMonth?: Dayjs;
	secondMonth?: Dayjs;
	setFirstMonth: Setter<Dayjs>;
	setSecondMonth: Setter<Dayjs>;
	setDateRange: Setter<DateRange>;
	helpers: {
		inHoverRange: (day: Dayjs) => boolean;
	};
	handlers: {
		onDayClick: (day: Dayjs) => void;
		onDayHover: (day: Dayjs) => void;
		onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
	};
}

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
	const classes = useStyles();
	const [datesVisible, setDatesVisible] = useState(false);

	// @ts-ignore
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

	const {
		ranges,
		dateRange,
		minDate,
		maxDate,
		firstMonth = dayjs(),
		setFirstMonth,
		secondMonth = dayjs().add(1, 'month'),
		setSecondMonth,
		setDateRange,
		helpers,
		handlers,
	} = props;

	const { startDate, endDate } = dateRange;
	const canNavigateCloser = secondMonth.diff(firstMonth, 'month') >= 2;
	const commonProps = {
		dateRange,
		minDate,
		maxDate,
		helpers,
		handlers,
	};
	return (
		<Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
			{!datesVisible && (
				<Grid>
					<DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} setOtherRange={() => setDatesVisible(true)} />
				</Grid>
			)}

			{datesVisible && (
				<Grid>
					<Grid container className={classes.header} alignItems="center">
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">{startDate ? startDate.format('MMMM DD, YYYY') : 'Start Date'}</Typography>
						</Grid>
						<Grid item className={classes.headerItem}>
							<ArrowRightAlt color="action" />
						</Grid>
						<Grid item className={classes.headerItem}>
							<Typography variant="subtitle1">{endDate ? endDate.format('MMMM DD, YYYY') : 'End Date'}</Typography>
						</Grid>
					</Grid>
					<Divider />
					<Grid container direction={isMobile ? 'column' : 'row'} justifyContent="center" wrap="nowrap">
						<Month
							{...commonProps}
							value={firstMonth}
							setValue={setFirstMonth}
							navState={[true, canNavigateCloser]}
							marker={MARKERS.FIRST_MONTH}
						/>
						<div className={classes.divider} />
						<Month
							{...commonProps}
							value={secondMonth}
							setValue={setSecondMonth}
							navState={[canNavigateCloser, true]}
							marker={MARKERS.SECOND_MONTH}
						/>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default Menu;
