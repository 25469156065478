import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  appBar: {
    color: "#fff",
    backgroundColor: "#6670a7",
    boxShadow: "none",
    position: "fixed",
    top: 0,
    left: 0,
    textAlign: "center",
    width: "100%",
    padding: "10px 0",
    height: 50,
    zIndex: 1,
  },
  heading: {
    fontSize: "1.2rem",
    width: "100%",
    marginBottom: 0,
  },
  container: {
    height: "100svh",
    paddingTop: 50,
    paddingBottom: 70,
    overflow: "auto",
  },
});

export default function ContentComponent({ children }: any) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.appBar} id="appName">
        <Typography variant="h6" className={classes.heading}>
          Homegamemanager
        </Typography>
      </div>
      <Container className={classes.container}>{children}</Container>
    </>
  );
}
