import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";
import { useNavigate } from "react-router-dom";
import TableService from "../core/service/table.service";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import { useToast } from "../core/hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    headingWBorder: {
      fontWeight: 500,
      fontSize: 19,
      paddingLeft: 40,
      paddingRight: 40,
      textAlign: "center",
      position: "relative",
      paddingBottom: 7,
      marginTop: 50,
    },
    btn: {
      minWidth: 100,
    },
  })
);

export default function InviteView({
  onAccept,
  onDecline,
  tableId,
  pendingInvUser,
}: {
  tableId?: number;
  onAccept?: () => void;
  onDecline?: () => void;
  pendingInvUser?: TablePlayerInterface;
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const tableService = new TableService();
  const { showToast } = useToast();

  const acceptInvite = async () => {
    if (!pendingInvUser || !tableId) return;
    try {
      await tableService.acceptInvite(tableId, pendingInvUser.phone);
      if (onAccept) onAccept();
      showToast("Invite accepted!", "success");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    }
  };

  const declineInvite = async () => {
    if (!pendingInvUser || !tableId) return;
    try {
      await tableService.declineInvite(tableId, pendingInvUser.phone);
      navigate("/");
      showToast("Invite declined!", "warning");
      if (onDecline) onDecline();
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    }
  };

  return (
    <Grid container>
      <Typography
        className={classes.headingWBorder}
        variant="h5"
        gutterBottom
        style={{ top: -30, position: "relative" }}
      >
        To play in this table, please accept the invite first.
      </Typography>

      <Grid
        item
        xs={12}
        textAlign="center"
        justifyContent="space-around"
        display="flex"
        p={1}
      >
        <Button
          variant="contained"
          onClick={declineInvite}
          color="secondary"
          className={classes.btn}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          onClick={acceptInvite}
          color="primary"
          className={classes.btn}
        >
          Accept
        </Button>
      </Grid>
    </Grid>
  );
}
