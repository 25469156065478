import Grid from "@mui/material/Grid";
import BasePage from "../core/base.page";
import ProfileForm from "./profile.form";

export default function ProfilePage() {
  return (
    <BasePage>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        width="100%"
        height="100%"
      >
        <ProfileForm />
      </Grid>
    </BasePage>
  );
}
