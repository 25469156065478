const formatCurrency = (
  amount: number,
  currency: string,
  showDigits = true
) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const formatted = numberFormat.format(amount);

  if (showDigits) {
    return formatted.replace(/^(\d+)/, "<span>$1</span>");
  }
  // eslint-disable-next-line
  else return formatted.replace(/\d/g, "").replace(/\./g, "").replace(/,/g, "");
};

export default formatCurrency;
