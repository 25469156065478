import GameInfoCashier from "../game-info-cashier";
import GameInfoPlayer from "../game-info-player";
import GameTabsStyles from "./styles";
import { useAuth } from "../../core/context/auth.context";
import Typography from "@mui/material/Typography";
import { useGame } from "../game.context";

export default function TabInfo() {
  const { isCanceled, reloadGame, isCashier } = useGame();
  const { user } = useAuth();
  const classes = GameTabsStyles();

  if (isCanceled) {
    return (
      <div className={`${classes.baseContainer} ${classes.canceledContainer}`}>
        <Typography variant="h6">Game canceled</Typography>
      </div>
    );
  }

  if (isCashier) {
    return (
      <div className={`${classes.baseContainer} ${classes.statsContainer}`}>
        {!isCanceled && <GameInfoCashier />}
      </div>
    );
  }
  return (
    <div className={`${classes.baseContainer} ${classes.statsContainer}`}>
      <GameInfoPlayer
        user={user}
        onTransactionSent={reloadGame}
        onPlayerStatusChange={reloadGame}
      />
    </div>
  );
}
