import { useRef } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BasePage from "../core/base.page";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import useRouteMatch from "../core/hooks/use-route-match";
import { Link } from "react-router-dom";
import MyDebtsTab from "./tabs/my-debts-tab";
import TabReceived from "./tabs/tab-received";
import TabAllPayments from "./tabs/tab-all-payments";
import OwedToMeTab from "./tabs/owed-to-me-tab";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      height: "100%",
      alignContent: "flex-start",
    },
  })
);

export default function TransactionsPage() {
  const classes = useStyles({});
  const tableRef = useRef<HTMLInputElement>(null);

  const routeMatch = useRouteMatch([
    "/transactions/owed-to-me",
    "/transactions/my-debts",
    "/transactions/received_payments",
    "/transactions/all_transactions",
  ]);

  const currentTab = routeMatch?.pattern?.path || "/transactions/my-debts";

  return (
    <BasePage>
      <Grid container className={classes.container} ref={tableRef}>
        <Grid item xs={12} height={48}>
          <Tabs value={currentTab} centered aria-label="basic tabs example">
            <Tab
              label="My Debts"
              value="/transactions/my-debts"
              to="/transactions/my-debts"
              component={Link}
            />
            <Tab
              label="Owed to me"
              value="/transactions/owed-to-me"
              to="/transactions/owed-to-me"
              component={Link}
            />
            <Tab
              label="Received"
              value="/transactions/received_payments"
              to="/transactions/received_payments"
              component={Link}
            />
            <Tab
              label="All"
              value="/transactions/all_transactions"
              to="/transactions/all_transactions"
              component={Link}
            />
          </Tabs>
        </Grid>
        {!!currentTab && "/transactions/owed-to-me" === currentTab && (
          <OwedToMeTab />
        )}

        {!!currentTab && "/transactions/my-debts" === currentTab && (
          <MyDebtsTab />
        )}

        {!!currentTab && "/transactions/received_payments" === currentTab && (
          <TabReceived />
        )}

        {!!currentTab && "/transactions/all_transactions" === currentTab && (
          <TabAllPayments />
        )}
      </Grid>
    </BasePage>
  );
}
