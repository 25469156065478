import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";

interface PaymentStatusInterface {
  status?: UserDebtStatus;
  text?: string;
  payDate?: string | Date;
  showIcons?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginTop: 5,
      fontSize: 11,
      fontWeight: 300,
      height: 18,
      borderRadius: 5,
      clear: "both",
      maxWidth: "fit-content",
      "& .MuiChip-icon": {
        color: "#fff",
        fontSize: 11,
        marginTop: -1,
      },
    },
  })
);

/**
 * Displays a Chip with the given status of a debt.
 *
 * @param {object} props Component props
 * @param {UserDebtStatus} props.status The status of the debt
 * @param {string|Date} [props.payDate] The date the debt was paid
 * @param {string} [props.text] A custom text to display on the chip
 * @param {boolean} [props.showIcons] Whether to show an icon on the chip
 *
 * @returns {React.ReactElement} A Chip with the given status
 */
export default function DebtStatus({
  status,
  payDate,
  text,
  showIcons,
}: PaymentStatusInterface) {
  const classes = useStyles({});

  return (
    <>
      {status === UserDebtStatus.NEW && (
        <Chip
          className={classes.chip}
          label={text || "new"}
          color="info"
          icon={showIcons ? <WatchLaterIcon /> : undefined}
        />
      )}
      {status === UserDebtStatus.PENDING && (
        <Chip
          className={classes.chip}
          label={text || "pending"}
          color="warning"
          icon={showIcons ? <WatchLaterIcon /> : undefined}
        />
      )}
      {status === UserDebtStatus.PAID && (
        <Chip
          className={classes.chip}
          color="success"
          label={
            text ||
            `paid ${payDate ? `on ${dayjs(payDate).format("D MMMM")}` : ""}`
          }
          icon={showIcons ? <CheckCircleIcon /> : undefined}
        />
      )}
      {status === UserDebtStatus.DECLINED && (
        <Chip
          className={classes.chip}
          label={text || "declined"}
          color="error"
          icon={showIcons ? <CancelIcon /> : undefined}
        />
      )}
    </>
  );
}
