import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";
import TablePlayerStatus from "@homegame/common/dist/enum/table-player-status.enum";
import Grid from "@mui/material/Grid";
import TableService from "../core/service/table.service";
import BasePage from "../core/base.page";
import InviteToTableDialog from "../core/components/invite-to-table.component";
import CreateGameDialog from "../game/CreateGame/create-game.component";
import { useAuth } from "../core/context/auth.context";
import TableViewStyle from "./styles/table.view.style";
import InviteView from "./invite-view.component";
import TablePlayersComponent from "../core/components/table-players.component";
import UserRole from "@homegame/common/dist/enum/user-role.enum";
import GamesListComponent from "./games-list.component";
import { useSocket } from "../core/context/socket.context";
import { useToast } from "../core/hooks/use-toast.hook";
import { useMutation, useQuery } from "@tanstack/react-query";
import ConfirmDialog from "../core/components/confirmation-dialog.component";

export default function TableViewPage() {
  const classes: any = TableViewStyle();
  const tableService = new TableService();
  const { showToast } = useToast();
  const { gameSocket, tableSocket } = useSocket();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);

  const navigate = useNavigate();

  const { tableId } = useParams();
  const { user } = useAuth();
  const [pendingInvUser, setPendingInvUser] = useState<
    TablePlayerInterface | undefined
  >();
  const [playersOpened, setPlayersOpened] = useState<boolean>(false);
  const [inviteOpened, setInviteOpened] = useState<boolean>(false);
  const [startGameOpened, setStartGameOpened] = useState<boolean>(false);

  const { data: table, refetch } = useQuery({
    queryKey: [`singleTableData`, { tableId }],
    queryFn: () => tableService.single(tableId),
    initialData: undefined,
  });

  useMutation({
    onError: (e: any) => {
      const { message } = e as Error;
      showToast(message, "error");
      navigate("/");
    },
  });

  useEffect(() => {
    if (table) {
      setPendingInvUser(
        user &&
          table.players &&
          table.players.find(
            (i) =>
              i.status === TablePlayerStatus.PENDING && i.phone === user.phone
          )
      );
    }
  }, [table]);

  const toggleInvite = () => setInviteOpened(!inviteOpened);
  const togglePlayers = () => setPlayersOpened(!playersOpened);

  const onPlayersCLose = async () => {
    await refetch();
    setPlayersOpened(false);
  };

  const onInviteCLose = async () => {
    await refetch();
    setInviteOpened(false);
  };

  const onGameClose = () => {
    setStartGameOpened(false);
  };

  const reloadTable = async () => {
    await refetch();
  };

  const closeConfirmation = () => setIsOpenConfirmation(false);
  const openConfirmation = () => {
    setIsOpenConfirmation(true);
  };

  const handleAction = async () => {
    setIsLoading(true);
    try {
      await tableService.delete(table?.id);
      showToast(`${table?.name} table deleted.`, "warning");
      navigate("/");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tableSocket) {
      tableSocket.on("tableCreated", (data) => {
        if (tableId && +tableId === +data.tableId) {
          refetch();
        }
      });
      tableSocket.on("tableUpdated", (data) => {
        if (tableId && +tableId === +data.tableId) {
          refetch();
        }
      });

      return () => {
        tableSocket.off("tableCreated");
        tableSocket.off("tableUpdated");
      };
    }
  }, [tableSocket]);

  useEffect(() => {
    if (gameSocket) {
      gameSocket.on("gameUpdated", (data) => {
        if (tableId && +tableId === +data.tableId) {
          refetch();
        }
      });
      gameSocket.on("gameCreated", (data) => {
        if (tableId && +tableId === +data.tableId) {
          refetch();
        }
      });

      return () => {
        gameSocket.off("gameUpdated");
        gameSocket.off("gameCreated");
      };
    }
  }, [gameSocket]);

  if (!user) {
    navigate("/");
    return <></>;
  }

  const toggleGame = () => setStartGameOpened(!startGameOpened);

  return (
    <BasePage>
      <Grid container spacing={0} className={classes.container}>
        <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
          <span>
            <ButtonGroup variant="text" color="primary">
              <IconButton
                component={Link}
                to="/tables"
                className={classes.iconBtn}
                aria-label="add new table"
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
              {table &&
                (table.userId === user.id ||
                  user?.role.includes(UserRole.SUPERADMIN)) && (
                  <>
                    <IconButton
                      className={classes.iconBtn}
                      onClick={toggleInvite}
                      size="large"
                      data-test-id="addPlayer"
                    >
                      <PersonAddIcon />
                    </IconButton>
                    {table.players && table.players.length > 0 && (
                      <IconButton
                        className={classes.iconBtn}
                        onClick={togglePlayers}
                        size="large"
                        data-test-id="playersList"
                      >
                        <GroupsIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={openConfirmation}
                      className={classes.iconBtn}
                      aria-label="delete table"
                      size="large"
                      data-test-id="deleteTable"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={toggleGame}
                      className={classes.iconBtn}
                      aria-label="add new game"
                      size="large"
                      data-test-id="createGame"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </>
                )}
            </ButtonGroup>
          </span>
        </Grid>
        <br />
        <br />
        <Grid item xs={12} display="flex" flexDirection="column" height="100%">
          <Typography
            className={classes.heading}
            variant="h5"
            gutterBottom
            style={{ top: -15, position: "relative" }}
          >
            {table?.name}
          </Typography>

          {pendingInvUser && (
            <InviteView
              onAccept={reloadTable}
              tableId={table?.id}
              pendingInvUser={pendingInvUser}
            />
          )}

          {!pendingInvUser && tableId && (
            <GamesListComponent tableId={+tableId} />
          )}
        </Grid>
      </Grid>
      {playersOpened && table && (
        <TablePlayersComponent
          table={table}
          onClose={onPlayersCLose}
          onAction={reloadTable}
        />
      )}
      {inviteOpened && (
        <InviteToTableDialog
          tableId={Number(tableId)}
          onClose={onInviteCLose}
        />
      )}
      {startGameOpened && (
        <CreateGameDialog
          table={table}
          userId={Number(user?.id)}
          onClose={onGameClose}
        />
      )}
      <ConfirmDialog
        text={`Are you sure you want to delete ${table?.name} table?`}
        isLoading={isLoading}
        actionText="Delete"
        open={isOpenConfirmation}
        onClose={closeConfirmation}
        action={handleAction}
      />
    </BasePage>
  );
}
