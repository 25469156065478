import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useState } from "react";
import AuthService from "../core/service/auth.service";
import { Controller, useForm } from "react-hook-form";
import PhoneInputComponent from "../core/components/phone-input.component";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "../core/components/loading-button.component";
import PhoneFormat from "../core/helpers/phone-format.helper";
import { useToast } from "../core/hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      width: "90%",
      marginLeft: "5%",
    },
    button: {
      width: "80%",
      marginTop: 50,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 13,
    },
  })
);

export default function SendOTPComponent({
  onSend,
}: {
  onSend?: ({ phone }: { phone: string }) => void;
}) {
  const classes = useStyles({});
  const authService = new AuthService();
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false);
  const { showToast } = useToast();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: { phone: "" } });

  const onSubmit = async ({ phone }: { phone: string }) => {
    setIsCodeSending(true);
    try {
      await authService.sendCode({ phone });
      if (onSend) onSend({ phone });
      showToast(`OTP code sent to ${PhoneFormat(phone)}!`, "success");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsCodeSending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Controller
        name="phone"
        control={control}
        rules={{
          required: "Field is required",
        }}
        render={({ field }) => (
          <PhoneInputComponent
            autofocus={true}
            {...field}
            onValidate={(isValid) => {
              if (isValid && errors.phone) clearErrors("phone");
              if (!isValid && !errors.phone)
                setError(
                  "phone",
                  {
                    type: "isPhoneValid",
                    message: "Phone number is not valid",
                  },
                  { shouldFocus: true }
                );
            }}
          />
        )}
      />
      {errors.phone && (
        <FormHelperText error>{errors.phone.message}</FormHelperText>
      )}

      <LoadingButton
        isLoading={isCodeSending}
        text="Send Code"
        disabled={!!Object.keys(errors).length}
        className={classes.button}
        color="secondary"
        type="submit"
      />
    </form>
  );
}
