import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ContentComponent from "./content.component";
import Grid from "@mui/material/Grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import BottomNavigationComponent from "./bottom-navigation.component";
import PullToRefreshComponent from "./pull-to-refresh.component";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      textAlign: "center",
      height: "100%",
    },
    icon: {
      color: "rgba(245,0,87,.6)",
      fontSize: 110,
      marginTop: 50,
    },
  })
);
export default function ErrorComponent({ resetState, error, info }: any) {
  const classes = useStyles();

  const onRefresh = () => {
    if (resetState) resetState();
  };

  return (
    <>
      <PullToRefreshComponent onRefresh={onRefresh}>
        <ContentComponent>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            className={classes.container}
          >
            <Grid item sm={12} xs={12}>
              <ErrorOutlineIcon className={classes.icon} />
              <Typography variant="h1" fontSize={33}>
                Oops!
              </Typography>
              {!error && (
                <Typography variant="h3" fontSize={25}>
                  Something went wrong
                </Typography>
              )}
              {error && (
                <Typography variant="h3" fontSize={25}>
                  {error.message}
                </Typography>
              )}
              <Typography variant="body1" fontSize={16}>
                Pull down to refresh
              </Typography>
            </Grid>
          </Grid>
        </ContentComponent>
      </PullToRefreshComponent>
      <BottomNavigationComponent />
    </>
  );
}
