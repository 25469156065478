import BaseService from "./base.service";
import TransactionInterface from "@homegame/common/dist/interface/transaction.interface";
import TransactionFilterInterface from "@homegame/common/dist/interface/filters/transaction-filter.interface";

export default class TransactionService extends BaseService {
  baseEndpoint = "transactions";

  async list(
    params?: TransactionFilterInterface
  ): Promise<[TransactionInterface[], number]> {
    const [data, total] = await this.baseList<TransactionInterface>({
      ...params,
    });
    return [data, total];
  }

  async save(
    data: Partial<TransactionInterface>
  ): Promise<TransactionInterface> {
    data.amount = Number(data.amount);
    data.chips = Number(data.chips);
    return this.baseCreate(data);
  }

  async approve(id: number): Promise<TransactionInterface> {
    return this.request({
      url: `${this.baseEndpoint}/${id}/approve`,
      method: "POST",
    });
  }

  async decline(id: number): Promise<TransactionInterface> {
    return this.request({
      url: `${this.baseEndpoint}/${id}/decline`,
      method: "POST",
    });
  }
}
