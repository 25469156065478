import { useState } from "react";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import Button from "@mui/material/Button";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import Grid from "@mui/material/Grid";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CreateTransactionDialog from "../core/components/create-transaction.component";
import getPlayerLastTransaction from "../core/hooks/useGameInfo/get-player-last-transaction";
import { useGame } from "./game.context";

interface PlayerTransactionsBtnsProps {
  user: UserInterface | undefined;
  onTransactionSent?: () => void;
}
export default function PlayerTransactionsBtns({
  user,
  onTransactionSent,
}: PlayerTransactionsBtnsProps) {
  const { game, isOngoing } = useGame();

  const [transactionDialogState, setTransactionDialogState] = useState<{
    open: boolean;
    type: TransactionType;
  }>({
    open: false,
    type: TransactionType.BUY_IN,
  });

  if (!user || !game) return <></>;

  const currentGamePlayer = game.players?.find((p) => p.playerId === user.id);

  const lastPlayerTransaction = getPlayerLastTransaction(
    user.id!,
    game.transactions
  );

  if (currentGamePlayer?.status !== GamePlayerStatus.REGISTERED) return <></>;

  const showBuyInDialog = async () => {
    setTransactionDialogState({ open: true, type: TransactionType.BUY_IN });
  };

  const showCashOutDialog = async () => {
    setTransactionDialogState({ open: true, type: TransactionType.CASH_OUT });
  };

  const onTransactionClose = () => {
    setTransactionDialogState({ open: false, type: TransactionType.CASH_OUT });
    if (onTransactionSent) onTransactionSent();
  };

  const getTransactionFrom = () => {
    if (transactionDialogState.type === TransactionType.BUY_IN) return user;
    if (transactionDialogState.type === TransactionType.CASH_OUT)
      return game.table?.user;
    return undefined;
  };

  const getTransactionTo = () => {
    if (transactionDialogState.type === TransactionType.BUY_IN)
      return game.table?.user;
    if (transactionDialogState.type === TransactionType.CASH_OUT) return user;
    return undefined;
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddShoppingCartIcon />}
        onClick={showBuyInDialog}
      >
        Buy-in
      </Button>

      {isOngoing &&
        lastPlayerTransaction?.type !== TransactionType.CASH_OUT && (
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ShoppingCartCheckoutIcon />}
              onClick={showCashOutDialog}
            >
              Cash out
            </Button>
          </Grid>
        )}

      <CreateTransactionDialog
        open={transactionDialogState.open}
        users={game.players?.map((p) => p.player) as UserInterface[]}
        type={transactionDialogState.type}
        game={game}
        onClose={onTransactionClose}
        toUser={getTransactionTo()}
        fromUser={getTransactionFrom()}
      />
    </>
  );
}
