import React, { createContext, FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

const SwipeNavContext = createContext(undefined);

interface SwipeNavProviderProps {
  children: ReactNode;
}

const SwipeNavProvider: FC<SwipeNavProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => navigate(1),
    onSwipedRight: (eventData) => navigate(-1),
  });

  return (
    <SwipeNavContext.Provider value={undefined} {...handlers}>
      <div {...handlers}>{children}</div>
    </SwipeNavContext.Provider>
  );
};
export { SwipeNavContext, SwipeNavProvider };
