import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import SouthIcon from "@mui/icons-material/South";
import SyncIcon from "@mui/icons-material/Sync";
import ReactPullToRefresh from "react-pull-to-refresh";

const useStyles = makeStyles((theme) =>
  createStyles({
    refreshContainer: {
      height: "100%",
      width: "100%",
    },
  })
);

export default function PullToRefreshComponent({ children, onRefresh }: any) {
  const classes = useStyles();
  const test = async function () {
    if (onRefresh) await onRefresh();
  };

  return (
    <ReactPullToRefresh
      className={classes.refreshContainer}
      onRefresh={test}
      icon={<SouthIcon />}
      loading={<SyncIcon />}
    >
      {children}
    </ReactPullToRefresh>
  );
}
