import React, { SyntheticEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BasePage from '../core/base.page';
import TableListComponent from '../tables/table-list.component';
import TransactionsListComponent from '../transactions/transactions-list.component';
import UsersListComponent from './users-list.component';

export default function AdminPage() {
	const [value, setValue] = useState('1');

	const handleChange = (event: SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<BasePage>
			<Box sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							<Tab label="Tables" value="1" />
							<Tab label="Transactions" value="2" />
							<Tab label="Users" value="3" />
						</TabList>
					</Box>
					<TabPanel value="1" style={{ padding: 0 }}>
						<TableListComponent />
					</TabPanel>
					<TabPanel value="2" style={{ padding: 0 }}>
						<TransactionsListComponent />
					</TabPanel>
					<TabPanel value="3" style={{ padding: 0 }}>
						<UsersListComponent />
					</TabPanel>
				</TabContext>
			</Box>
		</BasePage>
	);
}
