import { Dayjs } from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DateRangePicker from './DateRangePicker';
import { DateRange, DefinedRange } from '../types';

export interface DateRangePickerWrapperProps {
	open: boolean;
	toggle: () => void;
	initialDateRange?: DateRange;
	definedRanges?: DefinedRange[];
	minDate?: Dayjs | string;
	maxDate?: Dayjs | string;
	onChange: (dateRange: DateRange) => void;
}

const DateRangePickerWrapper: React.FunctionComponent<DateRangePickerWrapperProps> = (props: DateRangePickerWrapperProps) => {
	const { toggle, open } = props;

	return (
		<Dialog open={open} onClose={toggle}>
			<DateRangePicker {...props} />
		</Dialog>
	);
};

export default DateRangePickerWrapper;
