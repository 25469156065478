import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import { DateRange } from './types';

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);

dayjs.extend(isBetween);

export const identity = <T>(x: T) => x;

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
	Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size));

export const combine = (...args: any[]): string => args.filter(identity).join(' ');

// Date
export const getDaysInMonth = (date: Dayjs): Dayjs[] => {
	const monthStart = dayjs(date).startOf('month');
	const monthEnd = dayjs(date).endOf('month');
	const startWeek = monthStart.startOf('week');

	const endWeek = monthEnd.endOf('week');

	const days = [];
	for (let curr = startWeek; curr.isSameOrBefore(endWeek); ) {
		days.push(curr);
		curr = curr.add(1, 'day');
	}
	return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Dayjs) => (startDate && day.isSame(startDate, 'day')) as boolean;
export const isEndOfRange = ({ endDate }: DateRange, day: Dayjs) => (endDate && day.isSame(endDate, 'day')) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Dayjs) =>
	(startDate && endDate && (day.isBetween(startDate, endDate) || day.isSame(startDate, 'day') || day.isSame(endDate, 'day'))) as boolean;

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
	if (startDate && endDate) {
		return startDate.isSame(endDate, 'day');
	}
	return false;
};

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (date: Dayjs | string | Falsy, defaultValue: Dayjs) => {
	if (date) {
		const parsed = dayjs(date);
		if (parsed.isValid()) return parsed;
	}
	return defaultValue;
};

export const getValidatedMonths = (range: DateRange, minDate: Dayjs, maxDate: Dayjs) => {
	const { startDate, endDate } = range;
	if (startDate && endDate) {
		const newStart = dayjs.max(startDate, minDate);
		const newEnd = dayjs.max(endDate, maxDate);

		return [newStart, newStart?.isSame(newEnd, 'month') ? newStart.add(1, 'month') : newEnd];
	}
	return [startDate, endDate];
};
