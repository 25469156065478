import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/auth.context';

function PrivateRoute({ children }: { children: JSX.Element }) {
	const { user } = useAuth();
	const { pathname, search, hash } = useLocation();
	const redirect = [pathname, search, hash].join('');
	return user ? children : <Navigate to={`/sign?redirect=${redirect}`} replace={true} />;
}

export default PrivateRoute;
