import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontWeight: 400,
      textAlign: "center",
      width: "100%",
      fontSize: 22,
      paddingTop: 15,
      borderBottom: "2px solid rgba(245,0,87,.6)",
      paddingBottom: 10,
      "& + .heading-buttons": {
        marginTop: -52,
        "& .MuiButtonBase-root": {
          float: "right",
          padding: 5,
          color: "#fff",
          position: "relative",
          zIndex: 99,
        },
      },
    },
  })
);

export default function HeadingComponent({ text }: { text: string }) {
  const classes = useStyles({});
  return (
    <Typography
      className={classes.heading}
      variant="h1"
      gutterBottom
      id="pageHeading"
    >
      {text}
    </Typography>
  );
}
