import React, { createContext, useContext, useReducer } from 'react';

interface LoaderState {
	visible?: boolean;
}

interface LoaderContextInterface {
	loaderState: LoaderState;
	loaderDispatch: ({ type }: { type: 'hide' | 'show' }) => void;
	showLoader: () => void;
	hideLoader: () => void;
}

// @ts-ignore
const LoaderContext = createContext<LoaderContextInterface>();

const initialState: LoaderState = {
	visible: false,
};

const reducer = (state: LoaderState, action: { type: string; payload?: LoaderState | string }) => {
	switch (action.type) {
		case 'hide':
			return { ...state, visible: false };
		case 'show':
			return { ...state, visible: true };
		default:
			return initialState;
	}
};

function LoaderProvider(props: any) {
	const { children } = props;
	// @ts-ignore
	const [loaderState, loaderDispatch] = useReducer(reducer, initialState);
	// @ts-ignore
	const showLoader = () => loaderDispatch({ type: 'show' });
	// @ts-ignore
	const hideLoader = () => loaderDispatch({ type: 'hide' });
	const value = { loaderState, loaderDispatch, showLoader, hideLoader };
	return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
}

const useLoader = (): LoaderContextInterface => {
	const context = useContext(LoaderContext);
	if (context === undefined) {
		throw new Error('useLoader must be used within an LoaderContext');
	}
	return context;
};

export { LoaderContext, LoaderProvider, useLoader };
