export const API_URL = process.env.REACT_APP_API_URL || 'https://beta-api.homegamemanager.com/';
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const SIGN_UP_STRING = process.env.REACT_APP_SIGN_UP_STRING || '123456';

export const TOKEN_COOKIE_NAME = process.env.TOKEN_COOKIE_NAME || 'token';
export const REFRESH_TOKEN_COOKIE_NAME = process.env.REFRESH_TOKEN_COOKIE_NAME || 'refresh_token';

export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_APP_PROJECT_ID || '';
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_APP_API_KEY || '';
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_APP_ID || '';
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_APP_VAPID_KEY || '';
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN || '';
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_APP_STORAGE_BUCKET || '';
export const FIREBASE_SENDER_ID = process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID || '';
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID || '';
export const DOMAIN = process.env.DOMAIN || 'http://localhost:9000';
