import { forwardRef, useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import PhoneInput, { CountryData } from "react-phone-input-2";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { CountryCode } from "libphonenumber-js/types";
import { useAuth } from "../context/auth.context";

const useStyles = makeStyles(() =>
  createStyles({
    phoneInputDropBtn: {
      background: "rgba(0, 0, 0, 0.06) !important",
      border: "none !important",
      "& .flag": {
        backgroundSize: "auto",
      },
      "& .selected-flag:hover": {
        background: "rgba(0, 0, 0, 0.06) !important",
      },
      "& .selected-flag:focus": {
        background: "rgba(0, 0, 0, 0.06) !important",
      },
    },
    phoneInputSearch: {
      background: "#616a9f !important",
      "& input.search-box::placeholder": {
        color: "rgba(255, 255, 255, 0.4)",
      },
      "& input.search-box": {
        backgroundColor: "#6670a7",
        border: "none !important",
        borderBottom: "1px solid white !important",
        padding: "12px  !important",
        width: "70%",
        color: "white",
      },
    },
    phoneInputDrop: {
      background: "#6670a7 !important",
      "& > li.active *": {
        color: "#333 !important",
      },
      "& li.highlight": {
        backgroundColor: "rgba(241,241,241, 0.28) !important",
      },
      "& li .dial-code": {
        color: "#fff !important",
        fontWeight: "bold",
      },
    },
    phoneInputCont: {
      borderBottom: "2px solid rgba(255,255,255,.4)",
      marginBottom: 10,
    },
    signInInput: {
      width: "100% !important",
      height: "auto !important",
      color: "#fff !important",
      background: "rgba(0, 0, 0, 0.06) !important",
      border: "none !important",
      font: "inherit",
      fontSize: "17px !important",
      padding: "19px 5px 15px",
      "& .MuiInputBase-root": {
        margin: 0,
      },
      "& input": {
        border: "none",
        paddingTop: 30,
        paddingBottom: 5,
      },
    },
  })
);

interface PhoneInputProps {
  name?: string;
  disabled?: boolean;
  showLabel?: boolean;
  error?: boolean;
  autofocus?: boolean;
  placeholder?: string;
  country?: string;
  className?: string;
  onValidate?: (isValid: boolean) => void;
  onChange?: (phone: string) => void;
  variant?: "outlined" | "filled" | "standard";
}

const PhoneInputComponent = forwardRef(
  (
    {
      name = "phone",
      autofocus = false,
      variant = "standard",
      onValidate,
      showLabel = false,
      className,
      country = "us",
      placeholder = "Enter phone number",
      disabled = false,
      error,
      onChange,
      ...rest
    }: PhoneInputProps,
    ref
  ) => {
    const classes: any = useStyles({});

    const { userLocation } = useAuth();
    const [selCountryCode, setSelCountryCode] = useState(
      userLocation?.countryCode
        ? userLocation?.countryCode.toLowerCase()
        : country
    );
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
      if (onValidate) onValidate(isValid);
    }, [isValid]);

    return (
      <PhoneInput
        {...rest}
        inputClass={classes.signInInput}
        searchClass={classes.phoneInputSearch}
        containerClass={classes.phoneInputCont}
        dropdownClass={classes.phoneInputDrop}
        buttonClass={classes.phoneInputDropBtn}
        inputStyle={{ color: "#7a7a7a" }}
        disabled={disabled}
        placeholder={placeholder}
        country={selCountryCode}
        enableSearch
        specialLabel={showLabel ? "Phone" : ""}
        inputProps={{
          ref,
          name,
          placeholder: "Enter phone number",
          autoFocus: autofocus || false,
        }}
        onChange={(inputPhone, countryData: CountryData) => {
          if (!countryData || !countryData.countryCode) return;
          if (countryData.countryCode !== selCountryCode) {
            setIsValid(true);
          }
          setSelCountryCode(countryData.countryCode);
          if (onChange) onChange(inputPhone);
          setIsValid(
            isPossiblePhoneNumber(
              inputPhone,
              countryData.countryCode.toUpperCase() as CountryCode
            )
          );
          return inputPhone;
        }}
        // // @ts-ignore
        onBlur={(e) =>
          setIsValid(
            isPossiblePhoneNumber(
              e.target.value,
              selCountryCode.toUpperCase() as CountryCode
            )
          )
        }
        isValid={() => isValid}
      />
    );
  }
);

export default PhoneInputComponent;
