import { toast } from "react-toastify";

type ToastMessageType = "info" | "error" | "warning" | "success";

export const useToast = () => {
  const showToast = (message: string, type: ToastMessageType = "info") => {
    const opts = { toastId: message };
    switch (type) {
      case "info":
        toast.info(message, opts);
        break;
      case "error":
        toast.error(message, opts);
        break;
      case "success":
        toast.success(message, opts);
        break;
      case "warning":
        toast.warn(message, opts);
        break;
      default:
        toast(message, opts);
        break;
    }
  };
  return { showToast };
};
